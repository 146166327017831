import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {fetchP5, getDateString, getNewDate, postP5, putP5} from "../Utils";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Box, Checkbox,
    Drawer, FormControlLabel, FormGroup, InputLabel, Select,
    Switch,
    TableCell,
    TextField,
    Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import purify from "dompurify";
import MenuItem from "@mui/material/MenuItem";
import CustomTabPanel from "../components/CustomTabPanel";

const defaultFootballPicksPicks = {
    Pick1: null,
    Pick2: null,
    Pick3: null,
    Pick4: null,
    Pick5: null,
    Spread1: null,
    Spread2: null,
    Spread3: null,
    Spread4: null,
    Spread5: null,
    event_id1: null,
    event_id2: null,
    event_id3: null,
    event_id4: null,
    event_id5: null
};

function Ad(props) {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [fetchUrl, ] = useState(window.location.origin + '/api/v1/ad/pagedata');
    const [activity, setActivity] = useState([]);
    const [activityInfo, setActivityInfo] = useState({});
    const [availableGolfers, setAvailableGolfers] = useState([]);
    const [cacheStats, setCacheStats] = useState({});
    const [connUsers, setConnUsers] = useState([]);
    const [contestAgents, setContestAgents] = useState([]);
    const [hitCount, setHitCount] = useState({});
    const [lockedUsers, setLockedUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [addUserEmail, setAddUserEmail] = useState("");
    const [removeUserEmail, setRemoveUserEmail] = useState("");
    const [resetUserPasswordEmail, setResetUserPasswordEmail] = useState("");
    const [changeUserEmailOld, setChangeUserEmailOld] = useState("");
    const [changeUserEmailNew, setChangeUserEmailNew] = useState("");
    const [userContestEmail, setUserContestEmail] = useState("");
    const [userContests, setUserContests] = useState({Football:false, Golf:false, bbAuction:false, BowlMania:false});
    const [golfPicksEntryId, setGolfPicksEntryId] = useState("");
    const [golfPicksPicks, setGolfPicksPicks] = useState({pick1:0, pick2:0, pick3:0, pick4:0});
    const [pick1EntryId, setPick1EntryId] = useState("");
    const [pick1Pick, setPick1Pick] = useState(0);
    const [testResult, setTestResult] = useState('');
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [footballConfig, setFootballConfig] = useState({});
    const [footballAvailablePicks, setFootballAvailablePicks] = useState([]);
    const [footballEvents, setFootballEvents] = useState([]);
    const [footballPicksIn, setFootballPicksIn] = useState({});
    const [footballSmack, setFootballSmack] = useState([]);
    const [footballPicksPicks, setFootballPicksPicks] = useState(defaultFootballPicksPicks);
    const [footballPicksUserEmail, setFootballPicksUserEmail] = useState('');
    
    const fetchAdData = () => {
        fetchP5(props.getState().userName, props.getState().password, fetchUrl, 'ad', props)
            .then(data => {

                setActivity(props.getState().ad.data.activity.data);
                setActivityInfo(props.getState().ad.data.activity_info.data[0]);
                setAvailableGolfers(props.getState().ad.data.available_golfers.data);
                setCacheStats(props.getState().ad.data.cache_stats.data[0]);
                setConnUsers(props.getState().ad.data.conn_users.data);
                setContestAgents(props.getState().ad.data.contest_agents.data);
                setHitCount(props.getState().ad.data.hit_count.data[0]);
                setLockedUsers(props.getState().ad.data.locked_users.data);
                setUsers(props.getState().ad.data.users.data);

                if (isLoading) {
                    setIsLoading(false);
                }
            });
    };

    const fetchFootballAdData = () => {
        fetchP5(props.getState().userName, props.getState().password, '/api/v1/football/ad', 'footballAd', props)
            .then(data => {
                setFootballConfig(props.getState().footballAd.data.config.data[0]);
                setFootballAvailablePicks(props.getState().footballAd.data.available_picks.data);
                setFootballEvents(props.getState().footballAd.data.events.data);
                setFootballPicksIn(props.getState().footballAd.data.picks_in.data[0]);
                setFootballSmack(props.getState().footballAd.data.smack.data);
            });
    };

    useEffect(() => {
        document.title = "Pick 5 Admin";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            fetchAdData();
            fetchFootballAdData();
        }
    }, [isLoading]);

    function renderLoading() {
        return <div>Loading</div>;
    }

    function renderHitCounts() {
        return (
            <div>
                <h3>Hit Counts</h3>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>Football</TableCell>
                                <TableCell>{(hitCount.football_HitCount).toLocaleString()}</TableCell>
                                <TableCell>{getDateString(getNewDate(hitCount.football_LastHitTime))}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Golf</TableCell>
                                <TableCell>{(hitCount.golf_HitCount).toLocaleString()}</TableCell>
                                <TableCell>{getDateString(getNewDate(hitCount.golf_LastHitTime))}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>bbAuction</TableCell>
                                <TableCell>{(hitCount.bbAuction_HitCount).toLocaleString()}</TableCell>
                                <TableCell>{getDateString(getNewDate(hitCount.bbAuction_LastHitTime))}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
    
    function handleAgentSwitch(e, agentName) {
        const active = (e.currentTarget.checked ? 1 : 0);
        const url = 'api/v1/ad/toggleagent?name=' + agentName + '&active=' + active;
        fetchP5(props.getState().userName, props.getState().password, url)
            .then(result => {
                if (result && result['result'] === 'success') {
                    fetchAdData();
                }
            });
    }
    
    function renderContestAgents() {
        return (
            <div>
                <h3>Contest Agents</h3>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Active</TableCell>
                                <TableCell>Refresh Rate</TableCell>
                                <TableCell>Last Runtime</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contestAgents.map((agent) => (
                                <TableRow key={`contestAgent${agent.name}`}>
                                    <TableCell>{agent.name}</TableCell>
                                    <TableCell>
                                        <Switch size="small" checked={!!agent.active} onChange={(e) => handleAgentSwitch(e, agent.name)}/>
                                    </TableCell>
                                    <TableCell>{agent.refresh_rate}</TableCell>
                                    <TableCell>{getDateString(getNewDate(agent.last_runtime))}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }

    function renderCacheStats() {
        return (
            <div>
                <h3>Cache Stats</h3>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>DB Server Uptime</TableCell>
                                <TableCell>{activityInfo.uptime}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Queries</TableCell>
                                <TableCell>{Number(activityInfo.queries).toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>apc_hits</TableCell>
                                <TableCell>{cacheStats.apc_hits.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>apc_misses</TableCell>
                                <TableCell>{cacheStats.apc_misses.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>apc_mem_free</TableCell>
                                <TableCell>{cacheStats.apc_mem_free}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }

    function handleUnlockUser(email) {
        const url = 'api/v1/ad/UnlockUser?userName=' + email;
        fetchP5(props.getState().userName, props.getState().password, url)
            .then(result => {
                if (result && result['result'] === 'success') {
                    fetchAdData();
                }
            });
    }
    
    function renderLockedUsers() {
        return (
            <div>
                <h3>Locked Users</h3>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            {lockedUsers.map((user) =>
                                <TableRow key={`lockedUser_${user.Email}`}>
                                    <TableCell className="AdLockedUserCell">
                                        <div>{user.Email}</div>
                                        <Button size="small" onClick={() => handleUnlockUser(user.Email)}>Unlock</Button>
                                    </TableCell>
                                </TableRow>    
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
    
    function renderUserList() {
        return (
            <div>
                <h3>Users</h3>
                <TableContainer className="AdMainScrollTable">
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>FB Entry Name</TableCell>
                                <TableCell>Golf Entry Name</TableCell>
                                <TableCell>Joined</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.sort((a,b) => a.Email.localeCompare(b.Email)).map((user) => 
                                <TableRow key={`usersTable_${user.Email}`}>
                                    <TableCell>{user.Email}</TableCell>
                                    <TableCell><div dangerouslySetInnerHTML={{ __html: purify.sanitize(user.football_entry_name) }} /></TableCell>
                                    <TableCell><div dangerouslySetInnerHTML={{ __html: purify.sanitize(user.golf_entry_name) }} /></TableCell>
                                    <TableCell>{user.joined}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
    
    function renderActivityList() {
        return (
            <div>
                <h3>Activity</h3>
                <TableContainer className="AdMainScrollTable">
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Action</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>DB User</TableCell>
                                <TableCell>IP</TableCell>
                                <TableCell>Modtime</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activity.sort((a,b) => b.ID - a.ID).map((row) =>
                                <TableRow key={`activityRow_${row.ID}`}>
                                    <TableCell>{row.Action}</TableCell>
                                    <TableCell>{row.User}</TableCell>
                                    <TableCell>{row.DBUser}</TableCell>
                                    <TableCell>{row.IP}</TableCell>
                                    <TableCell>{getDateString(getNewDate(row.modtime))}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
    
    function renderConnectedUsers() {
        return (
            <div>
                <h3>Connected Users</h3>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            {connUsers.map((user) =>
                                <TableRow key={`connUserRow_${user.username}`}>
                                    <TableCell>{user.username}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
    
    function renderAdData() {
        return (
            <div>
                <div className="AdDataContainer">
                    {renderHitCounts()}
                    {renderContestAgents()}
                    {renderCacheStats()}
                    {renderConnectedUsers()}
                    {renderLockedUsers()}
                </div>
                <div className="AdDataContainer">
                    {renderUserList()}
                    {renderActivityList()}
                </div>
            </div>
        );
    }
    
    const toggleToolsDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        
        setDrawerOpen(open);
    }

    function handleAddUserClick() {
        fetchP5(props.getState().userName, props.getState().password, `/api/v1/ad/adduser?email=${encodeURIComponent(addUserEmail)}`)
            .then(result => {
                fetchAdData();
            });
    }
    
    function handleRemoveUserClick() {
        fetchP5(props.getState().userName, props.getState().password, `/api/v1/ad/RemoveUser?email=${encodeURIComponent(removeUserEmail)}`)
            .then(result => {
                fetchAdData();
            });
    }
    
    function handleResetPasswordClick() {
        fetchP5(props.getState().userName, props.getState().password, `/api/v1/ad/ResetPassword?userName=${encodeURIComponent(resetUserPasswordEmail)}`)
            .then(result => {
                fetchAdData();
            });
    }
    
    function handleChangeEmailClick() {
        fetchP5(props.getState().userName, props.getState().password, `/api/v1/ad/changeuseremail?oldEmail=${encodeURIComponent(changeUserEmailOld)}&newEmail=${encodeURIComponent(changeUserEmailNew)}`)
            .then(result => {
                fetchAdData();
            });
    }
    
    function handleUserContestChange(event) {
        const newContests = {...userContests};
        newContests[event.target.name] = event.target.checked;
        setUserContests(newContests);
        
        fetchP5(props.getState().userName, props.getState().password, `/api/v1/ad/EnableDisableContest?email=${encodeURIComponent(userContestEmail)}&football=${newContests.Football?1:0}&golf=${newContests.Golf?1:0}`)
            .then(result => {
                fetchAdData();
            });
    }
    
    function handleGolfPicksPickChange(e, pickNum) {
        setGolfPicksPicks({...golfPicksPicks, [`pick${pickNum+1}`]: e.target.value});
    }
    
    function handleSaveGolfPicksClick() {
        fetchP5(props.getState().userName, props.getState().password, `/api/v1/ad/MakeGolfPicks?entry_id=${golfPicksEntryId}&pick1=${golfPicksPicks.pick1}&pick2=${golfPicksPicks.pick2}&pick3=${golfPicksPicks.pick3}&pick4=${golfPicksPicks.pick4}`)
            .then(result => {
                fetchAdData();
            });
    }
    
    function handleUserContestSelect(userEmail) {
        const user = users.filter((u) => u.Email === userEmail).pop();
        setUserContests({
            Football: !!user.Football,
            Golf: !!user.Golf,
            bbAuction: !!user.bbAuction,
            BowlMania: !!user.BowlMania,
        });
        setUserContestEmail(userEmail);
    }
    
    function handleGolfPicksEmailChange(e) {
        fetchP5(props.getState().userName, props.getState().password, '/api/v1/ad/golf_picks?entry_id=' + e.target.value)
            .then(result => {
                if (result && result.data && result.data.picks && result.data.picks.data) {
                    const picks = result.data.picks.data;
                    if (picks.length === 0) {
                        setGolfPicksPicks({
                            pick1: 0,
                            pick2: 0,
                            pick3: 0,
                            pick4: 0,
                        });
                    }
                    else {
                        setGolfPicksPicks({
                            pick1: picks[0].Pick1,
                            pick2: picks[0].Pick2,
                            pick3: picks[0].Pick3,
                            pick4: picks[0].Pick4,
                        });
                    }
                }
                setGolfPicksEntryId(e.target.value);
            });
    }
    
    function handleFootballPicksEmailChange(e) {
        fetchP5(props.getState().userName, props.getState().password, '/api/v1/football/pending_picks?user=' + encodeURIComponent(e.target.value))
            .then(result => {
                if (result && result.picks && result.picks.data) {
                    const picks = result.picks.data;
                    if (picks.length === 0) {
                        setFootballPicksPicks(defaultFootballPicksPicks);
                    }
                    else {
                        setFootballPicksPicks(picks[0]);
                    }
                }
                setFootballPicksUserEmail(e.target.value);
            });
    }
    
    function handleFootballPicksPickChange(e, pickNum) {
        const pick = footballAvailablePicks.filter((pick) => pick.Pick === e.target.value).pop();
        setFootballPicksPicks({
            ...footballPicksPicks, 
            [`Pick${pickNum+1}`]: pick.Pick,
            [`Spread${pickNum+1}`]: null,
            [`event_id${pickNum+1}`]: pick.event_id
        });
    }
    
    function handleFootballPicksLockChange(e, pickNum) {
        const selectedPick = footballPicksPicks[`Pick${pickNum+1}`];
        let currentSpread = null;
        if (e.currentTarget.checked) {
            const pick = footballAvailablePicks.filter((pick) => pick.Pick === selectedPick).pop();
            currentSpread = pick.Spread;
        }
        setFootballPicksPicks({...footballPicksPicks, [`Spread${pickNum+1}`]: currentSpread});
    }
    
    function handleSaveFootballPicksClick() {
        const url = `/api/v1/football/MakePicksGuts?PickUser=${encodeURIComponent(footballPicksUserEmail)}&` +
            `Pick1=${footballPicksPicks.Pick1}&event_id1=${footballPicksPicks.event_id1}&lock1=${footballPicksPicks.Spread1 !== null ? 1 : 0}&`+
            `Pick2=${footballPicksPicks.Pick2}&event_id2=${footballPicksPicks.event_id2}&lock2=${footballPicksPicks.Spread2 !== null ? 1 : 0}&`+
            `Pick3=${footballPicksPicks.Pick3}&event_id3=${footballPicksPicks.event_id3}&lock3=${footballPicksPicks.Spread3 !== null ? 1 : 0}&`+
            `Pick4=${footballPicksPicks.Pick4}&event_id4=${footballPicksPicks.event_id4}&lock4=${footballPicksPicks.Spread4 !== null ? 1 : 0}&`+
            `Pick5=${footballPicksPicks.Pick5}&event_id5=${footballPicksPicks.event_id5}&lock5=${footballPicksPicks.Spread5 !== null ? 1 : 0}`;
        fetchP5(props.getState().userName, props.getState().password, url)
            .then(result => {
                fetchFootballAdData();
            });
    }

    function handlePick1EmailSelect(e) {
        setPick1EntryId(e.target.value);
        // fetch this user's current pick
        fetchP5(props.getState().userName, props.getState().password, '/api/v1/ad/pick1_golf_pick?entry_id=' + e.target.value)
            .then(result => {
                if (result && result.data && result.data.picks && result.data.picks.data && result.data.picks.data.length) {
                    setPick1Pick(result.data.picks.data[0].pick);
                }
                else {
                    setPick1Pick(0);
                }
            });
    }
    
    function handlePick1SaveClick() {
        fetchP5(props.getState().userName, props.getState().password, `/api/v1/ad/MakePick1GolfPick?entry_id=${pick1EntryId}&pick=${pick1Pick}`)
            .then(result => {
                fetchAdData();
            });
    }
    
    function handleTestButtonClick() {
        postP5(props.getState().userName, props.getState().password, `/api/v1/ad/test`, {})
            .then(response => response.json())
            .then(result => {
                setTestResult(JSON.stringify(result));
            });
    }
    
    function renderDrawer() {
        return (
            <Drawer
                open={drawerOpen}
                anchor="left"
                onClose={toggleToolsDrawer(false)}
            >
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Add User</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextField
                            label="Email"
                            value={addUserEmail}
                            onChange={(e) => setAddUserEmail(e.currentTarget.value)}
                            size="small"
                            fullWidth
                        />
                        <Button onClick={handleAddUserClick} size="small">Add User</Button>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Remove User</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InputLabel id="remove-user-email-label">Email</InputLabel>
                        <Select value={removeUserEmail} onChange={(e) => setRemoveUserEmail(e.target.value)} fullWidth size="small" labelId="remove-user-email-label">
                            {users.map((user) =>
                                <MenuItem key={`removeUserItem-${user.Email}`} value={user.Email}>{user.Email}</MenuItem>    
                            )}
                        </Select>
                        <Button onClick={handleRemoveUserClick} size="small">Remove User</Button>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Reset Password</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InputLabel id="reset-password-email-label">Email</InputLabel>
                        <Select value={resetUserPasswordEmail} onChange={(e) => setResetUserPasswordEmail(e.target.value)} size="small" fullWidth labelId="reset-password-email-label">
                            {users.map((user) =>
                                <MenuItem key={`resetUserPasswordItem-${user.Email}`} value={user.Email}>{user.Email}</MenuItem>
                            )}
                        </Select>
                        <Button size="small" onClick={handleResetPasswordClick}>Reset Password</Button>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Change Email</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InputLabel id="current-user-email-label">Current Email</InputLabel>
                        <Select value={changeUserEmailOld} onChange={(e) => setChangeUserEmailOld(e.target.value)} size="small" labelId="current-user-email-label" fullWidth>
                            {users.map((user) =>
                                <MenuItem key={`changeUserEmailItem-${user.Email}`} value={user.Email}>{user.Email}</MenuItem>
                            )}
                        </Select>
                        <TextField
                            label="New Email"
                            value={changeUserEmailNew}
                            onChange={(e) => setChangeUserEmailNew(e.currentTarget.value)}
                            size="small"
                            fullWidth
                        />
                        <Button size="small" onClick={handleChangeEmailClick}>Change Email</Button>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Enable/Disable Contest For User</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InputLabel id="user-contest-email-label">Email</InputLabel>
                        <Select value={userContestEmail} onChange={(e) => handleUserContestSelect(e.target.value)} size="small" labelId="user-contest-email-label" fullWidth>
                            {users.map((user) =>
                                <MenuItem key={`userContestEmailItem-${user.Email}`} value={user.Email}>{user.Email}</MenuItem>
                            )}
                        </Select>
                        <div>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={userContests.Football} name="Football" onClick={handleUserContestChange}/>} label="Football"/>
                                <FormControlLabel control={<Checkbox checked={userContests.Golf} name="Golf" onClick={handleUserContestChange}/>} label="Golf"/>
                                {/* The API doesn't support these next two so don't show em
                                <FormControlLabel control={<Checkbox checked={userContests.BowlMania} name="BowlMania" onClick={handleUserContestChange}/>} label="BowlMania"/>
                                <FormControlLabel control={<Checkbox checked={userContests.bbAuction} name="bbAuction" onClick={handleUserContestChange}/>} label="bbAuction"/>
                                */}                                
                            </FormGroup>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Golf Picks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InputLabel id="golf-picks-email-label">Email</InputLabel>
                        <Select value={golfPicksEntryId} onChange={handleGolfPicksEmailChange} size="small" labelId="golf-picks-email-label" fullWidth>
                            {users.filter((u) => u.Golf).map((user) =>
                                <MenuItem key={`golfPicksEmailItem-${user.Email}`} value={user.golf_entry_id}>{user.Email}</MenuItem>
                            )}
                        </Select>
                        {[...Array(4).keys()].map((pickNum) =>
                            <div>
                                <InputLabel id={`golfPicksSelectPick${pickNum}`}>{`Pick ${pickNum+1}`}</InputLabel>
                                <Select value={golfPicksPicks[`pick${pickNum + 1}`]} size="small" fullWidth onChange={(e) => handleGolfPicksPickChange(e, pickNum)} 
                                        children={availableGolfers.filter((g) => g.Group_Num === pickNum).map((golfer) => {return <MenuItem value={golfer.Golfer_ID} key={golfer.Golfer_ID}>{golfer.Name}</MenuItem>})}
                                />
                            </div>
                        )}
                        <Button size="small" onClick={handleSaveGolfPicksClick}>Save</Button>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Pick 1 Golf</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InputLabel id="pick1-email-label">Email</InputLabel>
                        <Select value={pick1EntryId} onChange={handlePick1EmailSelect} size="small" labelId="pick1-email-label" fullWidth>
                            {users.filter((u) => u.pick1golf_entry_id).map((user) =>
                                <MenuItem key={`golfPicksEmailItem-${user.Email}`} value={user.pick1golf_entry_id}>{user.Email}</MenuItem>
                            )}
                        </Select>
                        <InputLabel id="pick1-pick-label">Pick</InputLabel>
                        <Select value={pick1Pick} onChange={(e) => setPick1Pick(e.target.value)} size="small" labelId="pick1-pick-label" fullWidth>
                            {availableGolfers.map((golfer) =>
                                <MenuItem value={golfer.Golfer_ID}>{golfer.Name}</MenuItem>
                            )}
                        </Select>
                        <Button size="small" onClick={handlePick1SaveClick}>Save</Button>
                    </AccordionDetails>
                </Accordion>
                <Button onClick={() => setDrawerOpen(false)}>Close</Button>
            </Drawer>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function handleTabIndexChange(e, newValue) {
        setActiveTabIndex(newValue);
    }
    
    function handleSpreadChange(e, idx, spreadIdx) {
        const newEvents = [...footballEvents];
        newEvents[idx][`Spread${spreadIdx}`] = (e.currentTarget.value === '' ? null : e.currentTarget.value);
        newEvents[idx][`Spread${spreadIdx + 1 % 2}`] = newEvents[idx][`Spread${spreadIdx}`] * -1;
        setFootballEvents(newEvents);
    }
    
    function handleSpreadChangeSave(event) {
        putP5(props.getState().userName, props.getState().password, `/api/v1/football/events`, {
            id: event.ID,
            spread1: event.Spread1,
            spread2: event.Spread2
        })
            .then(response => response.json())
            .then(result => {
                fetchFootballAdData();
            });
    }
    
    function handleSmackDeleteClick(rowId) {
        fetchP5(props.getState().userName, props.getState().password, `/api/v1/football/ad_delete_smack?id=${rowId}`)
            .then(result => {
                if (result && result['result'] === 'success') {
                    fetchFootballAdData();
                }
            });
    }
    
    function renderFootballAdData() {
        return (
            <div>
                <div>{footballPicksIn.PicksIn} of {footballPicksIn.TotalUsers} picks in, {footballPicksIn.PicksLocked} locked</div>
                <div className="AdFootballDataColumnContainer">
                    <div className="AdFootballDataColumn">
                        <h4>Week {footballConfig.Week} Spreads</h4>
                        <TableContainer>
                            <Table size="small">
                                <TableBody>
                                    {footballEvents.map((event, idx) =>
                                        <TableRow>
                                            <TableCell>{event.Pick1}</TableCell>
                                            <TableCell className="AdFootballSpreadCell">
                                                <TextField
                                                    size="small"
                                                    type="number"
                                                    value={event.Spread1}
                                                    margin="none"
                                                    onChange={(e) => handleSpreadChange(e, idx, 1)}/>
                                            </TableCell>
                                            <TableCell>{event.Pick2}</TableCell>
                                            <TableCell className="AdFootballSpreadCell">
                                                <TextField
                                                    size="small"
                                                    type="number"
                                                    value={event.Spread2}
                                                    margin="none"
                                                    onChange={(e) => handleSpreadChange(e, idx, 2)}/>
                                            </TableCell>
                                            <TableCell><Button size="small" onClick={() => handleSpreadChangeSave(event)}>Save</Button></TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="AdFootballDataColumn AdFootballPicksPicksColumn">
                        <h4>User Picks Utility</h4>
                        <InputLabel id="football-picks-user-email-label">Email</InputLabel>
                        <Select size="small" labelId="football-picks-user-email-label" onChange={handleFootballPicksEmailChange} fullWidth value={footballPicksUserEmail}>
                            {users.filter((u) => u.Football).map((user) =>
                                <MenuItem key={`footballPicksUserItem-${user.Email}`} value={user.Email}>{user.Email}</MenuItem>
                            )}
                        </Select>
                        {[...Array(5).keys()].map((pickNum) =>
                            <div className="AdFootballPicksPicksRow">
                                <InputLabel id={`footballPicksSelectPick${pickNum}`}>{`Pick ${pickNum+1}`}</InputLabel>
                                <div>
                                    <Select value={footballPicksPicks[`Pick${pickNum + 1}`]} size="small"  onChange={(e) => handleFootballPicksPickChange(e, pickNum)}
                                            children={footballAvailablePicks.map((pick) => {return <MenuItem value={pick.Pick} key={`${pick.pick}-${pickNum}`}>{pick.Pick}</MenuItem>})}
                                    />
                                </div>
                                <InputLabel id={`footballPicksLockedPick${pickNum}`}>Locked</InputLabel>
                                <Checkbox size="small" labelId={`footballPicksLockedPick${pickNum}`} checked={footballPicksPicks[`Spread${pickNum+1}`] !== null} onChange={(e) => handleFootballPicksLockChange(e, pickNum)}/>
                            </div>
                        )}
                        <div className="AdFootballPicksPicksSaveContainer">
                            <Button size="small" onClick={handleSaveFootballPicksClick}>Save</Button>
                        </div>
                    </div>
                    <div className="AdFootballDataColumn">
                        <h4>Smack Table</h4>
                        <TableContainer>
                            <Table size="small">
                                <TableBody>
                                    {footballSmack.filter((row) => !row.archived).map((row) =>
                                        <TableRow key={`footballSmackRow-${row.ID}`}>
                                            <TableCell><div dangerouslySetInnerHTML={{ __html: purify.sanitize(row.EntryName) }} /><div>{getDateString(getNewDate(row.Modtime))}</div></TableCell>
                                            <TableCell><div className="AdFootballSmackMessage">{row.Message}</div></TableCell>
                                            <TableCell><Button size="small" onClick={() => handleSmackDeleteClick(row.ID)}>Delete</Button></TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        );
    }
    
    return (
        <div className="Ad">
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTabIndex} onChange={handleTabIndexChange}>
                        <Tab label="Admin" {...a11yProps(0)} />
                        <Tab label="Football Admin" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={activeTabIndex} index={0}>
                    {renderDrawer()}
                    <div className="AdHeader">
                        <Button onClick={toggleToolsDrawer(true)}>Tools</Button>
                        <Button onClick={handleTestButtonClick}>Test</Button>
                        <div className="AdTestResult">{testResult}</div>
                    </div>
                    {isLoading ? renderLoading() : renderAdData()}
                </CustomTabPanel>
                <CustomTabPanel value={activeTabIndex} index={1}>
                    {isLoading ? renderLoading() : renderFootballAdData()}
                </CustomTabPanel>
            </Box>
        </div>
    );
}

export default Ad;