import * as React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Switch} from "@mui/material";
import { styled } from '@mui/material/styles';

const notificationTypes = [
    {
        title: 'Scoring Play',
        key: 'scoring_play'
    },
    {
        title: 'Final',
        key: 'final',
    },
];

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        padding: theme.spacing(2),
    },
}));

export default function EventNotificationDialog(props) {
    const { onClose, enabledNotifications, open, eventId } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <CustomDialog onClose={handleClose} open={open}>
            <DialogTitle sx={{padding: '8px 16px'}}>Notifications</DialogTitle>
            <List sx={{ pt: 0 }}>
                {notificationTypes.map(notificationType => (
                    <ListItem disableGutters key={notificationType.key}>
                        <ListItemText primary={notificationType.title} />
                        <Switch
                            onChange={(e) => props.onUpdate(notificationType.key, e.target.checked, eventId)}
                            checked={enabledNotifications && enabledNotifications.includes(notificationType.key)}
                        />
                    </ListItem>
                ))}
            </List>
        </CustomDialog>
    );
}

EventNotificationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    enabledNotifications: PropTypes.string,
    onUpdate: PropTypes.func.isRequired,
    eventId: PropTypes.string.isRequired,
};
