import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {
    callRegisterForNot,
    deleteP5,
    fetchP5,
    postP5,
    useInterval
} from "../Utils";
import Footer from "../components/Footer";
import SmackboardTable from "../components/SmackboardTable";
import {Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import ScoresTable from "./ScoresTable";

const inProgressRefreshInterval = 30;
const notInProgressRefreshInterval = 60 * 10;
const url = new URL(window.location.origin + "/api/v1/bbauction/pagedata");
const params = new URLSearchParams(window.location.search);

if (params.get('year')) {
    url.searchParams.append('year', params.get('year'));
}
if (params.get('group')) {
    url.searchParams.append('group', params.get('group'));
}
const defaultUrl = url.toString();

function BBAuction(props) {
    let navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const [isLoading, setIsLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);
    const [fetchUrl, setFetchUrl] = useState(defaultUrl);
    const [refreshIn, setRefreshIn] = useState(inProgressRefreshInterval);
    const [refreshInterval, setRefreshInterval] = useState(inProgressRefreshInterval);
    const [configData, setConfigData] = useState({});
    const [smackData, setSmackData] = useState([]);
    const [ownersData, setOwnersData] = useState([]);
    const [teamsData, setTeamsData] = useState([]);
    const [versusData, setVersusData] = useState({});
    const [bracketData, setBracketData] = useState({});
    const [scoresData, setScoresData] = useState([]);
    const [year, setYear] = useState(params.get('year') || 0);
    const [group, setGroup] = useState(params.get('group') || 0);
    const [postButtonEnabled, setPostButtonEnabled] = useState(true);
    const [allTotalPaid, setAllTotalPaid] = useState(0);
    const [allTotalWon, setAllTotalWon] = useState(0);

    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, fetchUrl, 'bbAuction', props)
            .then(data => {
                const config = props.getState().bbAuction.data.config.data[0];
                setConfigData(config);
                setGroup(config.group_ids);
                if (props.getState().bbAuction.data.scores.data.some((event) => event.inProgress === 1)) {
                    setRefreshInterval(inProgressRefreshInterval);
                } else if (year === 0 || config.year === year) {
                    setRefreshInterval(notInProgressRefreshInterval);
                } else {
                    setRefreshInterval(0);
                }

                setYear(year || config.year);
                setSmackData(props.getState().bbAuction.data.smack.data.filter((message) => message.archived === 0));
                setScoresData(props.getState().bbAuction.data.scores.data);
                setOwnersData(props.getState().bbAuction.data.owners.data);
                setTeamsData(props.getState().bbAuction.data.teams.data);
                
                const totals = props.getState().bbAuction.data.owners.data.reduce((acc, val) => {
                    acc.AllTotalPaid += val.TotalPaid;
                    acc.AllTotalWon += val.TotalWon;
                    return acc;
                }, {
                    AllTotalWon: 0,
                    AllTotalPaid: 0
                });
                setAllTotalPaid(totals.AllTotalPaid);
                setAllTotalWon(totals.AllTotalWon);

                const versusObject = props.getState().bbAuction.data.versus.data
                    .sort((a,b) => {
                        const comp = a.owner.localeCompare(b.owner);
                        if (comp !== 0) {
                            return comp;
                        }
                        return a.opponent.localeCompare(b.opponent);
                    })
                    .reduce((acc, val) => {
                    if (acc[val.owner] === undefined) {
                        acc[val.owner] = {};
                    }
                    acc[val.owner][val.opponent] = val.winnings;
                    return acc;
                }, {});
                setVersusData(versusObject);
                
                const bracketObject = props.getState().bbAuction.data.bracket.data.reduce((acc, val) => (acc[val.ID] = val, acc), {});
                setBracketData(bracketObject);
                
                if (data.__delta) {
                    const url = new URL(data.__delta);
                    setFetchUrl(url.pathname + url.search);
                }
                setInitialLoad(false);
            });
    };

    useInterval(() => {
        if (refreshInterval) {
            if (refreshIn === 0 || window.p5MobileRefreshNow) {
                fetchData();
                setRefreshIn(refreshInterval);
                if (window.p5MobileRefreshNow) {
                    callRegisterForNot(props.getState().userName, props.getState().password);
                    delete window['p5MobileRefreshNow'];
                }
            } else {
                setRefreshIn(refreshIn - 1);
            }
        }
    }, 1000);

    useEffect(() => {
        document.title = "Pick 5 NCAA Tournament Auction";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            setIsLoading(false);
            fetchData();
        }
    }, [year, group]);

    function renderLoading() {
        return <div>Loading</div>;
    }

    function handleSmackPostMessage(message, onSuccess) {
        setPostButtonEnabled(false);
        postP5(props.getState().userName, props.getState().password, '/api/v1/bbauction/smack', {
            'Message': message,
            'group_id': group,
            'year': year,
        })
            .then(response => response.json())
            .then(() => {
                fetchData();
                setPostButtonEnabled(true);
                onSuccess();
            });
    }

    function handleSmackDeleteMessage(messageId) {
        deleteP5(props.getState().userName, props.getState().password, '/api/v1/football/smack?id=' + messageId)
            .then(response => response.json())
            .then(() => {
                setSmackData([...smackData.filter((message) => message.ID !== Number(messageId) && message.archived === 0)]);
            });
    }

    function renderStandingsTable() {
        return (
            <div>
                <h4>Standings</h4>
                <TableContainer component={Paper}>
                    <Table size="small" className="BBAuctionDataTable">
                        <TableHead>
                            <TableRow>
                                <TableCell>Owner</TableCell>
                                <TableCell>Total Paid</TableCell>
                                <TableCell>Total Won</TableCell>
                                <TableCell>Profit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ownersData.sort((a,b) => (b.TotalWon-b.TotalPaid) - (a.TotalWon-a.TotalPaid)).map((owner) =>
                                <TableRow className={owner.Name === configData.Name ? 'MyPickFontRow' : null} key={`standingsTableRow-${owner.Name}`}>
                                    <TableCell style={{color: owner.Color}}>{owner.Name}</TableCell>
                                    <TableCell>{owner.TotalPaid}</TableCell>
                                    <TableCell>{owner.TotalWon}</TableCell>
                                    <TableCell>{(owner.TotalWon - owner.TotalPaid)}</TableCell>
                                </TableRow>    
                            )}
                            <TableRow>
                                <TableCell>Totals</TableCell>
                                <TableCell>{allTotalPaid}</TableCell>
                                <TableCell>{allTotalWon}</TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
    
    function renderProfitByTeamTable() {
        return (<div>
            <h4>Profit by Team</h4>
            <TableContainer component={Paper}>
                <Table size="small" className="BBAuctionProfitByTeamTable BBAuctionDataTable">
                    <TableHead>
                        <TableRow>
                            <TableCell>Seed</TableCell>
                            <TableCell>Team</TableCell>
                            <TableCell>Owner</TableCell>
                            <TableCell>Won</TableCell>
                            <TableCell>Paid</TableCell>
                            <TableCell>Profit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teamsData.sort((a,b) => (b.Winnings - b.Price) - (a.Winnings - a.Price)).map((team) =>
                            <TableRow className={team.Owner === configData.Name ? 'MyPickFontRow' : null} key={`profitTableRow-${team.Name}`}>
                                <TableCell>{team.Seed}</TableCell>
                                <TableCell style={{color: team.Color}}>{team.Name}</TableCell>
                                <TableCell>{team.Owner}</TableCell>
                                <TableCell>{team.Winnings}</TableCell>
                                <TableCell>{team.Price}</TableCell>
                                <TableCell>{(team.Winnings - team.Price)}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>);
    }

    function renderStatisticsTable() {
        return (
            <div>
                <h4>Statistics</h4>
                <TableContainer component={Paper} className="BBAuctionStatsTableContainer">
                    <Table size="small" className="BBAuctionDataTable">
                        <TableHead>
                            <TableRow>
                                <TableCell>Owner</TableCell>
                                <TableCell>Avg Price</TableCell>
                                <TableCell>Total Paid</TableCell>
                                <TableCell>Total Teams</TableCell>
                                <TableCell>Teams Left</TableCell>
                                <TableCell>Avg Seed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ownersData.sort((a,b) => (b.TotalWon-b.TotalPaid) - (a.TotalWon-a.TotalPaid)).map((owner) =>
                                <TableRow className={owner.Name === configData.Name ? 'MyPickFontRow' : null} key={`statsTableRow-${owner.Name}`}>
                                    <TableCell style={{color: owner.Color}}>{owner.Name}</TableCell>
                                    <TableCell>{(Math.round((owner.TotalPaid / owner.TotalTeams) * 100) / 100)}</TableCell>
                                    <TableCell>{owner.TotalPaid}</TableCell>
                                    <TableCell>{owner.TotalTeams}</TableCell>
                                    <TableCell>{(owner.TotalTeams - owner.Losses)}</TableCell>
                                    <TableCell>{(Math.round(owner.avgSeed * 100) / 100)}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }

    function renderVersusTable() {
        return (
            <div>
                <h4>Head to Head</h4>
                <TableContainer component={Paper}>
                    <Table size="small" className="BBAuctionDataTable">
                        <TableHead>
                            <TableRow>
                                <TableCell>Owner</TableCell>
                                {Object.keys(versusData).map((owner) =>
                                    <TableCell key={`versusHeader-${owner}`}>{owner}</TableCell>    
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(versusData).map((owner) =>
                                <TableRow className={owner === configData.Name ? 'MyPickFontRow' : null} key={`versusTableRow-${owner}`}>
                                    <TableCell style={{color: ownersData.filter((o) => o.Name === owner)[0].Color}}>{owner}</TableCell>
                                    {Object.keys(versusData[owner]).map((opponent) =>
                                        <TableCell key={`versusTableCell-${owner}-${opponent}`}>{versusData[owner][opponent]}</TableCell>
                                    )}
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
    
    function bracketCellText(bracketData) {
        if (!bracketData) {
            return '';
        }
        const cn = bracketData['Color'] === configData.Color ? 'MyPickFont' : null;
        if (bracketData['Team']) {
            return <p
                className={cn}
                style={{color: `${bracketData['Color']}`}}
            >
                {`${bracketData['Seed']} ${bracketData['Team']} $${bracketData['Price']}`}
            </p>;
        }
        else if (bracketData['Seed']) {
            return <p>{`${bracketData['Seed']}. `}</p>;
        }
        else {
            return <p>&nbsp;</p>;
        }
    }
    
    function renderRegionBracketLeft(regionName, regionNum) {
        return (
            <TableContainer>
                <Table className="bbAuctionBracketTable" size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p1`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p2`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={4}>{bracketCellText(bracketData[`r${regionNum}p3`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={7}>{bracketCellText(bracketData[`r${regionNum}p4`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={16}>{bracketCellText(bracketData[`r${regionNum}p5`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p6`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p7`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p8`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p9`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p10`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p11`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={4}>{bracketCellText(bracketData[`r${regionNum}p12`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p13`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p14`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p15`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p16`])}</TableCell>
                            <TableCell className="bbAuctionBracketCellRegionName" rowSpan={2} align="center"><font size="4"><b>{regionName}</b></font></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p17`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p18`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={4}>{bracketCellText(bracketData[`r${regionNum}p19`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p21`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={7}>{bracketCellText(bracketData[`r${regionNum}p20`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p22`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p23`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p24`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p25`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p26`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={4}>{bracketCellText(bracketData[`r${regionNum}p27`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p28`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p29`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p30`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p31`])}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    function renderRegionBracketRight(regionName, regionNum) {
        return (
            <TableContainer>
                <Table className="bbAuctionBracketTable bbAuctionBracketTableRight" size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell" rowSpan={16}>{bracketCellText(bracketData[`r${regionNum}p1`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={7}>{bracketCellText(bracketData[`r${regionNum}p2`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={4}>{bracketCellText(bracketData[`r${regionNum}p3`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p4`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p5`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p6`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p7`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p8`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p9`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell" rowSpan={4}>{bracketCellText(bracketData[`r${regionNum}p10`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p11`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p12`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p13`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p14`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p15`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCellRegionName" rowSpan={2} align="center"><font size="4"><b>{regionName}</b></font></TableCell>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p16`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell" rowSpan={4}>{bracketCellText(bracketData[`r${regionNum}p18`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p19`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p20`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell" rowSpan={7}>{bracketCellText(bracketData[`r${regionNum}p17`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p21`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p22`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p23`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p24`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell" rowSpan={4}>{bracketCellText(bracketData[`r${regionNum}p25`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p26`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p27`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p28`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell" rowSpan={2}>{bracketCellText(bracketData[`r${regionNum}p29`])}</TableCell>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p30`])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r${regionNum}p31`])}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    function renderBracket() {
        return (
            <div className="bbAuctionBracketContainer">
                <div>
                    <div className="bbAuctionBracketRegionContainer">
                        {renderRegionBracketLeft("EAST", 1)}
                    </div>
                    <div className="BBAuctionBracketTitleContainer"><h2>{year} NCAA Tournament Auction</h2></div>
                    <div className="bbAuctionBracketRegionContainer">
                        {renderRegionBracketRight("SOUTH", 2)}
                    </div>
                </div>
                <div className="bbAuctionBracketNCContainer">
                    <TableContainer>
                        <Table size="small" className="bbAuctionBracketTable">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r5p1`])}</TableCell>
                                    <TableCell className="bbAuctionBracketCellEmpty"/>
                                    <TableCell className="bbAuctionBracketCell TextCenter">{bracketCellText(bracketData[`r5p2`])}</TableCell>
                                    <TableCell className="bbAuctionBracketCellEmpty"/>
                                    <TableCell className="bbAuctionBracketCell TextRight">{bracketCellText(bracketData[`r5p3`])}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="bbAuctionBracketNCLabel">National Champion</div>
                </div>
                <div>
                    <div className="bbAuctionBracketRegionContainer">
                        {renderRegionBracketLeft("WEST", 3)}                    
                    </div>
                    <div style={{width:'500px', display: 'inline-block'}}/>
                    <div className="bbAuctionBracketRegionContainer">
                        {renderRegionBracketRight("MIDWEST", 4)}
                    </div>
                </div>
                <div className="bbAuctionBracketNCContainer">
                    <TableContainer>
                        <Table size="small" className="bbAuctionBracketTable">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r6p1`])}</TableCell>
                                    <TableCell className="bbAuctionBracketCellEmpty"/>
                                    <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r6p2`])}</TableCell>
                                    <TableCell className="bbAuctionBracketCellEmpty"/>
                                    <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r6p3`])}</TableCell>
                                    <TableCell className="bbAuctionBracketCellEmpty"/>
                                    <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r6p4`])}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r6p5`])}</TableCell>
                                    <TableCell className="bbAuctionBracketCellEmpty"/>
                                    <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r6p6`])}</TableCell>
                                    <TableCell className="bbAuctionBracketCellEmpty"/>
                                    <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r6p7`])}</TableCell>
                                    <TableCell className="bbAuctionBracketCellEmpty"/>
                                    <TableCell className="bbAuctionBracketCell">{bracketCellText(bracketData[`r6p8`])}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        );
    }
    
    function changeGroup(group_id) {
        if (group !== group_id) {
            const url = new URL(window.location.origin + "/api/v1/bbauction/pagedata");
            url.searchParams.append('group', group_id);
            url.searchParams.append('year', year);
            delete (props.getState().bbAuction);
            setIsLoading(true);
            setInitialLoad(true);
            setFetchUrl(url.toString());
            setGroup(group_id);
        }
    }
    
    function renderGroupPicker() {
        if (!configData.group_ids) {
            return '';
        }
        const group_ids = configData.group_ids.split(',');
        if (group_ids.length < 2) {
            return '';
        }
        return (
            <div className="bbAuctionGroupPickerContainer">
                {group_ids.map((gid) => {
                        const params = new URLSearchParams(window.location.search);
                        params.set('group', gid);
                        return (<Link className='ContestLink' key={`groupLink-${gid}`} to={window.location.pathname + '?' + params.toString()} onClick={() => changeGroup(gid)}>{`Group ${gid}`}</Link>);
                    }
                )}
            </div>
        );
    }
    
    function renderAuctionData() {
        return (
            <div>
                {renderGroupPicker()}
                {renderBracket()}
                <div className="BBAuctionStandingsGridContainer">
                    <div>
                        {renderStandingsTable()}
                        {renderProfitByTeamTable()}
                    </div>
                    <div>
                        {ScoresTable(scoresData, false, null, configData)}
                    </div>
                    <SmackboardTable
                        smackboardData={smackData}
                        myEntryName={configData.Name || ''}
                        onDeleteMessage={handleSmackDeleteMessage}
                        onPostMessage={handleSmackPostMessage}
                        postButtonEnabled={postButtonEnabled}
                    />
                    <div>
                        {renderStatisticsTable()}
                        {renderVersusTable()}
                    </div>
                </div>
            </div>
        );
    }
    
    return (
        <div className="BBAuction">
            {initialLoad ? renderLoading() : renderAuctionData()}
            <Footer/>
        </div>
    );
}

export default BBAuction;