import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import purify from "dompurify";

function rowClass(row, myEntryName) {
    if (row.EntryName === myEntryName) {
        return 'GolfLeaderboardRowMyPick';
    }
    return '';
}

function standingsSort(a,b) {
    if (a.Score !== b.Score) {
        return a.Score - b.Score;
    }
    else if (a.Points !== b.Points) {
        return b.Points - a.Points;
    }
    return a.EntryName.localeCompare(b.EntryName);
}

export default function StandingsTable(standingsData, configData) {
    return (
        <div className="GolfStandingsGridItem">
            <h4>Standings</h4>
            <TableContainer component={Paper} className="GolfStandingsGridItemTable">
                <Table size="small" className="standingsTable Pick5DataTable">
                    <TableHead>
                        <TableRow>
                            <TableCell>Entry</TableCell>
                            <TableCell align="right">Score</TableCell>
                            <TableCell align="right">Points</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {standingsData
                            .filter((row) => row.Tournament_ID === 0)
                            .sort(standingsSort)
                            .map((row) => (
                            <TableRow key={`GolfStandingsRow${row.EntryName}`} className={rowClass(row, configData.EntryName)}>
                                <TableCell align="left">
                                    <div dangerouslySetInnerHTML={{ __html: purify.sanitize(row.EntryName) }} />
                                </TableCell>
                                <TableCell align="right">{row.Score}</TableCell>
                                <TableCell align="right">{row.Points}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="GolfPotDivContainer">
                Hole in one pot: <div className="GolfPotDiv">{configData.aces_pot}</div>
            </div>
            <div className="GolfPotDivContainer">
                Pick the winner pot: <div className="GolfPotDiv">{configData.winner_pot}</div>
            </div>
        </div>
    );
}
