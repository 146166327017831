import {Link} from "react-router-dom";
import React from "react";

export default class Footer extends React.Component {
    render() {
        return (
            <div>
                <Link className="ContestLink" to='#' onClick={(e) => {window.location='mailto:pickfivesports@gmail.com';e.preventDefault();}} key='link-email'>Comments/Questions/Problems</Link>
                <Link className="ContestLink" to="./.." key='link-mainpage'>Main Page</Link>
            </div>
        )
    }
}
