import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {fetchP5, postP5} from "../Utils";
import Button from "@mui/material/Button";
import {Card, CardContent, FormControlLabel, FormGroup, Switch, TextField} from "@mui/material";
import Footer from "../components/Footer";

function AccountSettings(props) {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [fetchUrl,] = useState("/api/v1/utils/useroptions");
    const [accountSettings, setAccountSettings] = useState({});
    const [submitChangesResult,setSubmitChangesResult] = useState("");

    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, fetchUrl, 'accountSettings', props)
            .then(data => {
                setAccountSettings(data.data);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        document.title = "Pick 5 Account Settings";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            fetchData();
        }
    }, []);
    
    function handleSaveChanges() {
        postP5(props.getState().userName, props.getState().password, '/api/v1/utils/useroptions', {
            user_options: {
                ...accountSettings,
                weeklyReminder: accountSettings.main.weeklyReminder,
                mobileSmackNotifications: accountSettings.main.mobileSmackNotifications
            }
        })
            .then(response => response.json())
            .then(data => {
                setSubmitChangesResult(data.error ? data.error.message : "Success");
                setTimeout(() => {
                    setSubmitChangesResult('');
                    }, 3000);
                }
            );
    }
    
    function renderLoading() {
        return <div>Loading</div>
    }
    
    function renderAccountSettings() {
        const cardWidth = '1000px';
        return (<FormGroup>
            {!accountSettings.main ? '' :
                <div>
                    <h4 className="AccountSettingsContestHeader">Main</h4>                
                    <Card sx={{maxWidth: `${cardWidth}`}}>
                        <CardContent>
                            <div className="AccountSettingsContestContainer">
                                <FormControlLabel control={
                                    <Switch 
                                        checked={accountSettings.main.weeklyReminder === 1}
                                        onChange={(event) => setAccountSettings({
                                            ...accountSettings,
                                            main: {
                                                ...accountSettings.main,
                                                weeklyReminder: event.target.checked ? 1 : 0
                                            }
                                        })}
                                    />
                                } 
                                    label="Weekly Email Reminder: Get an email when picks are available to be submitted."
                                />
                                <FormControlLabel 
                                    control={
                                        <Switch 
                                            checked={accountSettings.main.mobileSmackNotifications === 1} 
                                            onChange={(event) => setAccountSettings({
                                                ...accountSettings,
                                                main: {
                                                    ...accountSettings.main,
                                                    mobileSmackNotifications: event.target.checked ? 1 : 0
                                                }
                                            })}
                                        />
                                    } 
                                    label="Mobile Notifications: Get mobile phone notifications for smack board posts and contest specific alerts (iOS users only)."
                                />
                            </div>
                        </CardContent>
                    </Card>
                </div>
            }
            {!accountSettings.bowl_mania ? '' :
                <div>
                    <h4 className="AccountSettingsContestHeader">Bowl Mania</h4>
                    <Card sx={{maxWidth: `${cardWidth}`}}>
                        <CardContent>
                            <div className="AccountSettingsContestContainer">
                                <TextField
                                    label="EntryName"
                                    size="small"
                                    value={accountSettings.bowl_mania.EntryName}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(event => setAccountSettings({
                                        ...accountSettings,
                                        bowl_mania: {
                                            ...accountSettings.bowl_mania,
                                            EntryName: event.target.value
                                        }
                                    }))}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </div>
            }
            {!accountSettings.football ? '' :
                <div>
                    <h4 className="AccountSettingsContestHeader">Football</h4>
                    <Card sx={{maxWidth: `${cardWidth}`}}>
                        <CardContent>
                            <div className="AccountSettingsContestContainer">
                                <TextField
                                    label="EntryName"
                                    size="small"
                                    value={accountSettings.football.EntryName}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(event => setAccountSettings({
                                        ...accountSettings,
                                        football: {
                                            ...accountSettings.football,
                                            EntryName: event.target.value
                                        }
                                    }))}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </div>
            }
            {!accountSettings.golf ? '' :
                <div>
                    <h4 className="AccountSettingsContestHeader">Golf</h4>
                    <Card sx={{maxWidth: `${cardWidth}`}}>
                        <CardContent>
                            <div className="AccountSettingsContestContainer">
                                <TextField
                                    label="EntryName"
                                    size="small"
                                    value={accountSettings.golf.EntryName}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(event => setAccountSettings({
                                        ...accountSettings,
                                        golf: {
                                            EntryName: event.target.value
                                        }
                                    }))}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </div>
            }
        </FormGroup>);
    }
    
    return (isLoading ? renderLoading() : 
        <div>
            <h3>Pick 5 Account Settings</h3>
            {renderAccountSettings()}
            <div className="AccountSettingsSaveChangesContainer">
                <Button className="AccountSettingsSaveChangesButton" size="large" onClick={handleSaveChanges}>Save Changes</Button>
                <div className={`AccountSettingsSaveResult ${submitChangesResult === 'Success' ? 'SubmitResultSuccess' : (submitChangesResult === '' ? '' : 'SubmitResultError')}`}>{submitChangesResult}</div>
            </div>
            <Footer/>
        </div>
    );
}

export default AccountSettings;
