import React, {Fragment, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {fetchP5, postP5} from "../Utils";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import MenuItem from '@mui/material/MenuItem';
import {FormControl, InputLabel, Select} from "@mui/material";
import BasketballEventCell from "../components/BasketballEventCell";

function BBSquares(props) {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [fetchUrl, setFetchUrl] = useState("/api/v1/bbsquares/pagedata");
    const [squaresData, setSquaresData] = useState([]);
    const [configData, setConfigData] = useState({});
    const [standingsData, setStandingsData] = useState([]);
    const [contestId, setContestId] = useState(0);
    const [entryMap, setEntryMap] = useState([]);
    const [eventsData, setEventsData] = useState([]);

    const standingsSortFn = (a,b) => {
        if (a.wins !== b.wins) {
            return b.wins - a.wins;
        }
        if (a.entry_name === null && b.entry_name === null) {
            return 0;
        }
        if (a.entry_name === null) {
            return 1;
        }
        if (b.entry_name === null) {
            return -1;
        }
        return a.entry_name.localeCompare(b.entry_name);
    };
    
    const fetchData = (url) => {
        fetchP5(props.getState().userName, props.getState().password, url, 'bbsquares', props)
            .then(data => {
                setConfigData(data.data.config.data);
                setContestId(data.data.config.data.contest_id);
                setEntryMap(data.data.entries.data);
                setEventsData(data.data.events.data);
                
                setStandingsData(
                    data.data.squares.data
                    .filter((square) => square.wins > 0)
                    .sort(standingsSortFn));                
                
                // Split the flat array of squares into 10 arrays of 10 squares each.  The data
                // should be in order from the DB.
                setSquaresData(data.data.squares.data.reduce((all,one,i) => {
                    const ch = Math.floor(i/10);
                    all[ch] = [].concat((all[ch]||[]),one);
                    return all
                }, []));
                
                setIsLoading(false);
            });
    };
    
    useEffect(() => {
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            fetchData(fetchUrl);
        }
    }, []);

    function renderLoading() {
        return <div>Loading</div>;
    }

    function handleColHeaderSave(e) {
        const [x_pos,x_score] = e.target.value.split(':');
        postP5(props.getState().userName, props.getState().password, '/api/v1/bbsquares/savesquare',
            {
                'type': 'column',
                'pos': Number(x_pos),
                'score': Number(x_score),
                'contest_id': contestId,
            })
            .then(response => response.json())
            .then(() => fetchData(fetchUrl));
    }
    
    function handleRowHeaderSave(e) {
        const [y_pos,y_score] = e.target.value.split(':');
        postP5(props.getState().userName, props.getState().password, '/api/v1/bbsquares/savesquare',
            {
                'type': 'row',
                'pos': Number(y_pos),
                'score': Number(y_score),
                'contest_id': contestId,
            })
            .then(response => response.json())
            .then(() => fetchData(fetchUrl));
    }
    
    function handleSquareEntrySave(e) {
        const [x_pos,y_pos,entry_id] = e.target.value.split(':');
        postP5(props.getState().userName, props.getState().password, '/api/v1/bbsquares/savesquare',
            {
                'type': 'square',
                'x_pos': Number(x_pos),
                'y_pos': Number(y_pos),
                'entry_id': Number(entry_id),
                'contest_id': contestId,
            })
            .then(response => response.json())
            .then(() => fetchData(fetchUrl));
    }
    
    function renderSquaresData() {
        return (
            <div className="BBSquaresGridContainer">
                <div className="BBSquaresWinsContainer">
                    <h4>Squares Standings</h4>
                    <TableContainer component={Paper} className="BBSquaresTableContainer">
                        <Table size="small" className="Pick5DataTable">
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell>Wins</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {standingsData.map((row) =>
                                    <TableRow>
                                        <TableCell>{row.entry_name} ({row.x_score},{row.y_score})</TableCell>
                                        <TableCell>{row.wins}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="BBSquaresLosingScoreContainer">
                    <h4 className="BBSquaresLosingScoreHeader">Losing Score</h4>
                </div>
                <div className="BBSquaresSquaresTableContainer">
                    <h4 className="BBSquaresWinningScoreHeader">Winning Score</h4>
                    <TableContainer component={Paper} className="BBSquaresTableContainer">
                        <Table size="small" className="BBSquaresSquaresTable Pick5DataTable">
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    {squaresData[0].map((square) =>
                                        <TableCell key={`squarestablexheader${square.x_pos}`}>
                                            {square.x_score !== null ? square.x_score : (
                                                <FormControl size="small">
                                                <Select onChange={handleColHeaderSave}>
                                                    {[...Array(10).keys()].map((index) =>
                                                        <MenuItem value={`${square.x_pos}:${index}`} key={`${square.x_pos}:${index}`}>{index}</MenuItem>
                                                    )}
                                                </Select>
                                                </FormControl>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {squaresData.map((row) =>
                                    <TableRow key={`squarestablerow${row[0].y_pos}`}>
                                        <TableCell key={`squarestableyheader${row[0].y_pos}`}>
                                            {row[0].y_score !== null ? row[0].y_score : (
                                                <FormControl size="small">
                                                    <Select onChange={handleRowHeaderSave}>
                                                        {[...Array(10).keys()].map((index) =>
                                                            <MenuItem value={`${row[0].y_pos}:${index}`} key={`headerMenuItem${row[0].y_pos}:${index}`}>{index}</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </TableCell>
                                        {row.map((square) =>
                                            <TableCell key={`squarestablecell${square.x_pos}${square.y_pos}`} className={square.entry_name === configData.my_entry_name ? "BBSquaresMyEntry" : ""}>
                                                {square.entry_name !== null ?
                                                    (<Fragment>
                                                        <div className={`BBSquaresSquareWinsCell ${square.wins ? "BBSquaresSquareWinsCellWinner" : ""}`}>{square.wins}</div>
                                                        {square.entry_name}
                                                    </Fragment>)
                                                : (
                                                    <FormControl size="small">
                                                        <Select onChange={handleSquareEntrySave}>
                                                            {entryMap.map((entry) =>
                                                                <MenuItem value={`${square.x_pos}:${square.y_pos}:${entry.id}`} key={`${square.x_pos}:${square.y_pos}:${entry.id}`}>{entry.entry_name}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </TableCell>
                                        )}
                                    </TableRow>    
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="BBSquaresEventsContainer">
                    <h4>Games</h4>
                    <TableContainer component={Paper} className="BBSquaresEventsTableContainer">
                        <Table className="BBSquaresEventsTable" size="small">
                            {eventsData.map((event) =>
                                <TableRow>
                                    <TableCell>
                                        <BasketballEventCell
                                            team1={event.team1}
                                            score1={event.score1}
                                            team2={event.team2}
                                            score2={event.score2}
                                        />
                                        <div className="BBSquaresEventsEntryDiv">
                                            {`${event.entry_name} (${event.x_score},${event.y_score})`}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </Table>
                    </TableContainer>
                </div>
            </div>
        );
    }

    function handleContestIdChange(e) {
        const newUrl = '/api/v1/bbsquares/pagedata?contest_id='+e.target.value;
        setFetchUrl(newUrl);
        setContestId(e.target.value);
        fetchData(newUrl);
    }
    
    function renderContestSelect() {
        return (
            <Fragment>
                {isLoading || configData.contest_ids.length < 1 ? '' : (
                    <div className="BBSquaresContestSelectContainer">
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="demo-select-small">Contest Group</InputLabel>
                            <Select
                                size="small"
                                value={contestId}
                                labelId="demo-select-small"
                                label="Contest Group"
                                onChange={handleContestIdChange}
                            >
                            {configData.contest_ids.map((contest_id) =>
                                <MenuItem key={'contestSelcetItem'+contest_id} value={contest_id}>{contest_id}</MenuItem>
                            )}
                        </Select>
                        </FormControl>
                    </div>
                )}
            </Fragment>
        );
    }
    
    return (
        <div className="BBSquaresRootContainer">
            <div className="BBSquaresHeaderContainer">
                <h2>
                    NCAA Squares {configData.year}
                </h2>
                {renderContestSelect()}
            </div>
            {isLoading ? renderLoading() : renderSquaresData()}
            <div>
                <br/>
                <Link className="ContestLink" to='#' onClick={(e) => {window.location='mailto:pickfivesports@gmail.com';e.preventDefault();}} key='link-email'>Comments/Questions/Problems</Link>
                <Link className="ContestLink" to="/" key='link-mainpage'>Main Page</Link>
            </div>
        </div>
    );
}

export default BBSquares;