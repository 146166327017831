import React, {useEffect, useState, useRef} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {
    callRegisterForNot,
    deleteP5,
    fetchP5,
    footballEntryNameClass,
    footballResultClass,
    pickString,
    postP5,
    useInterval
} from "../Utils";
import Footer from "../components/Footer";
import SmackboardTable from "../components/SmackboardTable";
import StandingsTable from "./StandingsTable";
import ScoresTable from "./ScoresTable";
import PicksTable from "./PicksTable";
import {Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import purify from "dompurify";

const inProgressRefreshInterval = 30;
const notInProgressRefreshInterval = 60 * 10;
const url = new URL(window.location.origin + "/api/v1/football/pagedata");
const params = new URLSearchParams(window.location.search);

if (params.get('week')) {
    url.searchParams.append('week', params.get('week'));
}
if (params.get('year')) {
    url.searchParams.append('year', params.get('year'));
}
const defaultUrl = url.toString(); 
    
function Football(props) {
    let navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const [isLoading, setIsLoading] = useState(true);
    const [fetchUrl, setFetchUrl] = useState(defaultUrl);
    const [refreshIn, setRefreshIn] = useState(inProgressRefreshInterval);
    const [refreshInterval, setRefreshInterval] = useState(inProgressRefreshInterval);
    const [configData, setConfigData] = useState({});
    const [smackData, setSmackData] = useState([]);
    const [scoresData, setScoresData] = useState([]);
    const [standingsData, setStandingsData] = useState([]);
    const [myPicks, setMyPicks] = useState([]);
    const [picks, setPicks] = useState([]);
    const [year, setYear] = useState(params.get('year') || 0);
    const [week, setWeek] = useState(params.get('week') || 0);
    const [pickSummaryData, setPickSummaryData] = useState([]);
    const [cigarData, setCigarData] = useState([]);
    const picksTable = useRef(null);
    const [pickSummaryTableHeight, setPickSummaryTableHeight] = useState(100);
    const [notificationSupport,setNotificationSupport] = useState(false);
    const [smackPostEnabled, setSmackPostEnabled] = useState(true);
    const [totalWeeks, setTotalWeeks] = useState(0);
    
    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, fetchUrl, 'football', props)
            .then(data => {
                const config = props.getState().football.data.config.data[0];
                setConfigData(config);
                if (props.getState().football.data.scores.data.some((event) => event.inProgress === 1)) {
                    setRefreshInterval(inProgressRefreshInterval);
                }
                else if (config.ShowWeek === week) {
                    setRefreshInterval(notInProgressRefreshInterval);
                }
                else {
                    setRefreshInterval(0);
                }

                processPicks(props.getState().football.data.picks.data, config.EntryName);
                setWeek(week || config.Week);
                setYear(year || config.Year);
                setTotalWeeks(year && config.Year !== year ? 15 : config.Week);
                setNotificationSupport(config.notification_support);
                setSmackData(props.getState().football.data.smack.data.filter((message) => message.archived === 0));
                setStandingsData(props.getState().football.data.standings.data);
                setScoresData(props.getState().football.data.scores.data);
                setCigarData(props.getState().football.data.cigar.data);

                if (data.__delta) {
                    const url = new URL(data.__delta);
                    setFetchUrl(url.pathname + url.search);
                }

                onNextFrame(() => {
                    if (picksTable.current) {
                        setPickSummaryTableHeight(picksTable.current.offsetHeight);
                    }
                });
            });
    };

    useInterval(() => {
        if (refreshInterval) {
            if (refreshIn === 0 || window.p5MobileRefreshNow) {
                fetchData();
                setRefreshIn(refreshInterval);
                if (window.p5MobileRefreshNow) {
                    callRegisterForNot(props.getState().userName, props.getState().password);
                    delete window['p5MobileRefreshNow'];
                }
            }
            else {
                setRefreshIn(refreshIn - 1);
            }
        }
    }, 1000);

    useEffect(() => {
        document.title = "Pick 5 Football";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            setIsLoading(false);
            fetchData();
        }
    }, [week]);

    function processPicks(picksData, myEntryName) {
        let sortedPicks = [];
        let summaryData = {};
        let myPicksData = [];
        picksData.forEach((pick) => {
            let currentEntry = sortedPicks.find((sp) => sp.EntryName === pick.EntryName);
            if (!currentEntry) {
                currentEntry = {
                    EntryName: pick.EntryName,
                    Wins: 0,
                    Losses: 0,
                    picks: []
                };
                sortedPicks.push(currentEntry);
            }
            currentEntry.Wins += (pick.Result === 1 ? 1 : 0);
            currentEntry.Losses += (pick.Result === 0 ? 1 : 0);
            currentEntry.picks.push(pick);
            currentEntry.picks.sort((a,b) => a.Day.localeCompare(b.Day));
            if (pick.EntryName === myEntryName) {
                myPicksData.push(pick);
            }
            const ps = pickString(pick.Pick, pick.Spread);
            if (summaryData[ps]) {
                summaryData[ps]['count']++;
            }
            else {
                summaryData[ps] = {
                    count: 1,
                    day: pick.Day,
                    pick: pick.Pick,
                    result: pick.Result,
                    spread: pick.Spread
                };
            }
        });

        sortedPicks.sort((a,b) => {
            if (a.Losses !== b.Losses) {
                return a.Losses - b.Losses;
            }
            if (b.Wins !== a.Wins) {
                return b.Wins - a.Wins;
            }
            return a.EntryName.localeCompare(b.EntryName);
        });

        setPicks(sortedPicks);
        setMyPicks(myPicksData);

        const temp = Object.entries(summaryData);
        setPickSummaryData(temp.sort((a,b) => {
            if (b[1].count !== a[1].count) {
                return b[1].count - a[1].count;
            }
            return a[1].pick.localeCompare(b[1].pick);
        }));
    }
    
    function renderLoading() {
        return <div>Loading</div>;
    }

    function handleSmackPostMessage(message, onSuccess) {
        setSmackPostEnabled(false);
        postP5(props.getState().userName, props.getState().password, '/api/v1/football/smack', {
            'Message': message
        })
            .then(response => response.json())
            .then(() => {
                fetchData();
                setSmackPostEnabled(true);
                onSuccess();
            });
    }

    function handleSmackDeleteMessage(messageId) {
        deleteP5(props.getState().userName, props.getState().password, '/api/v1/football/smack?id=' + messageId)
            .then(response => response.json())
            .then(() => {
                setSmackData([...smackData.filter((message) => message.ID !== Number(messageId) && message.archived === 0)]);
            });
    }

    function handleNotificationUpdate(notificationType, active, eventId) {
        const url = "/api/v1/utils/register_for_notifications?event_id=" + eventId + "&type=" + notificationType + "&active=" + (active ? '1' : '0');
        fetchP5(props.getState().userName, props.getState().password, url)
            .then(() => {
                    const newScoresData = [...scoresData];
                    const eventIdx = newScoresData.findIndex((event) => event.ID === eventId);
                    if (eventIdx !== -1) {
                        let existingNotifications = newScoresData[eventIdx].notifications === null ? '' : newScoresData[eventIdx].notifications;
                        existingNotifications = existingNotifications.split(',').filter((n) => n !== '');
                        if (active) {
                            existingNotifications.push(notificationType);
                        }
                        else {
                            existingNotifications = existingNotifications.filter((notification) => notification !== notificationType);
                        }
                        newScoresData[eventIdx]['notifications'] = existingNotifications.join(',');
                        setScoresData(newScoresData);                        
                    }
                }
            );
    }
    
    function renderStandingsGrid() {
        return (<div className="FootballStandingsGridContainer">
            {StandingsTable(standingsData, week, year, configData)}
            {ScoresTable(scoresData, week, myPicks, notificationSupport, handleNotificationUpdate)}
            <SmackboardTable
                smackboardData={smackData}
                myEntryName={configData.EntryName || ''}
                onPostMessage={handleSmackPostMessage}
                onDeleteMessage={handleSmackDeleteMessage}
                postButtonEnabled={smackPostEnabled}
            />
        </div>);
    }

    function renderPickSummary() {
        return (
            <TableContainer component={Paper} style={{maxHeight:pickSummaryTableHeight}}>
                <Table size="small" className="FootballPickSummaryTable">
                    <TableHead>
                        <TableRow>
                            <TableCell>Pick</TableCell>
                            <TableCell>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pickSummaryData.map((row) => (
                            <TableRow key={`PickSummaryRow${row[1].pick}${row[1].spread}`}>
                                <TableCell className={footballResultClass(row[1].result)}>{pickString(row[1].pick, row[1].spread)}</TableCell>
                                <TableCell>{row[1].count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
    
    function onNextFrame(callback) {
        // NOTE: if the rendering gets weird and this stops working, try adding a setTimeout() with zero time
        // around this call.  It'll delay the execution enough to help, but may cause flashing on the screen.
        requestAnimationFrame(callback)
    }
    
    function renderCigarTable() {
        return (
            <TableContainer component={Paper}>
                <Table size="small" className="FootballCigarTable">
                    <TableHead>
                        <TableRow>
                            <TableCell>Close But No Cigar Standings</TableCell>
                            <TableCell>4 - 1 Weeks</TableCell>
                            <TableCell>Wins</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cigarData.map((row) => (
                            <TableRow key={`CigarTableRow${row.entry_name}`}>
                                <TableCell className={footballEntryNameClass(row.entry_name, configData.EntryName)}><div dangerouslySetInnerHTML={{ __html: purify.sanitize(row.entry_name) }} /></TableCell>
                                <TableCell>{row.cnt}</TableCell>
                                <TableCell>{row.wins}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
    
    function renderPicksTable() {
        return (
            <div className="FootballPicksContainer" ref={picksTable}>
                <div className="FootballPicksTableContainer">
                    {PicksTable(picks, configData)}
                </div>
                <div>
                    {renderPickSummary()}
                </div>
                <div className="FootballCigarTableContainer">
                    {renderCigarTable()}
                </div>
            </div>
        );
    }
    
    
    function renderFootballData() {
        return (
            <div className="FootballDataContainer">
                {renderPicksTable()}
                {refreshInterval ? <div className="FootballRefreshIn">Refresh in: {refreshIn} seconds</div> : ''}
                {renderStandingsGrid()}
            </div>
        );
    }
            
    function changeWeek(newWeek) {
        const url = new URL(window.location.origin + "/api/v1/football/pagedata");
        url.searchParams.append('week', newWeek);
        url.searchParams.append('year', year);
        setFetchUrl(url.toString());
        delete(props.getState().football);
        setWeek(newWeek);
        setIsLoading(true);
    }
    
    return (
        <div className="Football">
            <h3>
                Pick 5 Week {week}
            </h3>
            <div>
                <Link className="ContestLink" to="/accountsettings" key='link-accountsettings'>Account Settings</Link>
                <Link className="ContestLink" to="makepicks" key='link-footballmakepicks'>Make Picks</Link>
                <Link className="ContestLink" to="rules" key='link-footballrules'>Rules</Link>
                <Link className="ContestLink" to="alltime" key='link-footballalltime'>All Time</Link>
                {[...Array(totalWeeks).keys()].map((linkWeek) => {
                    return <Link key={"link-week-"+linkWeek} className="ContestLink" to="." onClick={() => changeWeek(linkWeek+1)}>{`Week ${linkWeek+1}`}</Link>
                })}
            </div>
            {isLoading ? renderLoading() : renderFootballData()}
            <Footer/>
        </div>
    );
}

export default Football;