import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';

function descendingComparator(a, b, orderBy, numeric) {
    const aValue = (numeric ? Number(a[orderBy]) : a[orderBy]),
        bValue = (numeric ? Number(b[orderBy]) : b[orderBy]);
    
    if (bValue < aValue) {
        return -1;
    }
    if (bValue > aValue) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy, numeric) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, numeric)
        : (a, b) => -descendingComparator(a, b, orderBy, numeric);
}

const DEFAULT_ORDER = 'asc';

function SortableTableHead(props) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (newOrderBy) => (event) => {
        const header = headCells.filter((cell) => cell.id === newOrderBy);
        onRequestSort(event, newOrderBy, header[0]?.numeric);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => {
                        const flexDirection = index === 0 ? 'row' : 'row-reverse';
                        return (
                            <TableCell
                                key={headCell.id}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                    style={{flexDirection: `${flexDirection}`}}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        );
                    }
                )}
            </TableRow>
        </TableHead>
    );
}

SortableTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default function SortableTable(props) {
    const {rows, defaultOrderBy, headCells, selected, multiSelect, onRowSelect, renderRow, defaultOrder, selectable, rowKey} = props;
    const [order, setOrder] = React.useState(defaultOrder || DEFAULT_ORDER);
    const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
    const [selectedRows, setSelectedRows] = React.useState(selected);
    const [visibleRows, setVisibleRows] = React.useState(null);

    React.useEffect(() => {
        const numeric = headCells.filter((cell) => cell.id === orderBy)[0]['numeric'];
        let rowsOnMount = rows.slice().sort(getComparator(order, orderBy, numeric));
        setVisibleRows(rowsOnMount);
        setSelectedRows(selected);
    }, [rows, selected, orderBy, order, headCells]);

    const handleRequestSort = React.useCallback(
        (event, newOrderBy, numeric) => {
            const isAsc = orderBy === newOrderBy && order === 'asc';
            const toggledOrder = isAsc ? 'desc' : 'asc';
            setOrder(toggledOrder);
            setOrderBy(newOrderBy);

            const sortedRows = rows.slice().sort(getComparator(toggledOrder, newOrderBy, numeric));
            setVisibleRows(sortedRows);
        },
        [rows, order, orderBy],
    );

    const handleClick = (event, name) => {
        if (!selectable) {
            return;
        }
        
        const selectedIndex = selectedRows.indexOf(name);
        let newSelected = [];

        if (multiSelect) {
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedRows, name);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedRows.slice(1));
            } else if (selectedIndex === selectedRows.length - 1) {
                newSelected = newSelected.concat(selectedRows.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedRows.slice(0, selectedIndex),
                    selectedRows.slice(selectedIndex + 1),
                );
            }
        }
        else {
            newSelected = [name];
        }

        setSelectedRows(newSelected);
        if (onRowSelect) {
            onRowSelect(event, name);
        }
    };

    const isSelected = (name) => selectedRows.indexOf(name) !== -1;

    return (
        <Box>
            <Paper className="SortableTablePaper">
                <TableContainer>
                    <Table
                        className="SortableTableTable Pick5DataTable"
                        aria-labelledby="tableTitle"
                        size={'small'}
                    >
                        <SortableTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows
                                ? visibleRows.map((row) => {
                                    const isItemSelected = isSelected(rowKey(row));
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, rowKey(row))}
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={rowKey(row)}
                                            selected={isItemSelected}
                                            sx={{'&.MuiTableRow-root.Mui-selected':{backgroundColor:'rgb(255, 255, 0)'}}}
                                        >
                                            {headCells.map((cell) => { return renderRow ? renderRow(row, cell) : (
                                                    <TableCell align="right" key={`sortableTableCell-${cell.id}-${row.Group_Num}-${rowKey(row)}`}>{row[cell.id]}</TableCell>
                                                )}
                                            )}
                                        </TableRow>
                                    );
                                })
                                : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}

SortableTable.propTypes = {
    headCells: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    defaultOrderBy: PropTypes.string.isRequired,
    selectable: PropTypes.bool.isRequired,
    rowKey: PropTypes.func.isRequired,
    defaultOrder: PropTypes.string,
    selected: PropTypes.array,
    multiSelect: PropTypes.bool,
    onRowSelect: PropTypes.func,
    renderRow: PropTypes.func,
}