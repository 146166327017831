import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {getDateString, getNewDate} from "../Utils";
import purify from "dompurify";
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from "prop-types";

export default class SmackboardTable extends React.Component {

    state = {
        newMessage: '',
    }
  
    handleDeleteClick = (e) => {
        this.props.onDeleteMessage(e.currentTarget.getAttribute('messageid'));
    };
    
    handlePostClick = (e) => {
        e.stopPropagation();
        if (this.state.newMessage !== '') {
            this.props.onPostMessage(this.state.newMessage, () => this.setState({
                newMessage: ''
                })
            );
        }
    };

    handleMessageChange = (e) => {
        this.setState({
            newMessage: e.target.value,
        });
    };
    
    render () {
        return (
            <div className="SmackboardTableContainer">
                <h4>Smack Board</h4>
                <div className="SmackboardTableInputContainer">
                    <TextField fullWidth multiline variant="outlined" size="small" onChange={this.handleMessageChange}
                               value={this.state.newMessage}/>
                    <Button onClick={this.handlePostClick} disabled={!this.props.postButtonEnabled}>Post</Button>
                </div>
                <TableContainer component={Paper} className="GolfStandingsGridItemTable">
                    <Table className="SmackBoardTable" size="small" aria-label="a dense table">
                        <TableBody>
                            {this.props.smackboardData
                                .sort((a, b) => {
                                    const aDate = new Date(a.Modtime), bDate = new Date(b.Modtime);
                                    return bDate - aDate;
                                })
                                .map((row) => (
                                    <TableRow key={"SmackboardTableRow" + row.ID}>
                                        <TableCell>
                                            <div dangerouslySetInnerHTML={{__html: purify.sanitize(row.EntryName)}}/>
                                            <div
                                                className="SmackBoardDate">{getDateString(getNewDate(row.Modtime))}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="SmackBoardMessageCell">
                                                <div className="SmackBoardMessage"
                                                     dangerouslySetInnerHTML={{__html: purify.sanitize(row.Message)}}/>
                                                {this.props.myEntryName === row.EntryName && (
                                                    <div className="SmackBoardMessageDeleteIconContainer">
                                                        <DeleteIcon className="SmackBoardMessageDeleteIcon"
                                                                    onClick={this.handleDeleteClick} messageid={row.ID}/>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

SmackboardTable.propTypes = {
    smackboardData: PropTypes.array.isRequired,
    myEntryName: PropTypes.string.isRequired,
    onPostMessage: PropTypes.func.isRequired,
    onDeleteMessage: PropTypes.func.isRequired, 
    postButtonEnabled: PropTypes.bool,
}