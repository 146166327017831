import React, {useEffect} from "react";
import {Link} from "react-router-dom";

function GolfRules() {

    useEffect(() => {
        document.title = "Pick 5 Golf Rules";
    }, []);

    return (
        <div>
            <h4>Pick 5 Golf Contest Rules</h4>
            <div>
                <p>Rules:
                    <ol>
                        <li>The overall prize goes to the entry with the lowest cumulative score.</li>
                        <li>If multiple entries tie for the overall prize they will split the prize.</li>
                        <li>
                            Payouts: $50 * 28 = $1400 total. 35 events (+ 5 majors count as double) * (9 + 7 + 5 + 4 + 3 + 2) =
                            1200 weekly prizes. $35 in aces pot + $35 winners pot + $130 for overall prizes: $32 + 28 + 24 + 20 + 16 + 10.
                            <br/>
                            Majors are:
                            <ol>
                                <li>Masters Tournament</li>
                                <li>The Players Championship</li>
                                <li>U.S. Open</li>
                                <li>The Open Championship</li>
                                <li>PGA Championship</li>
                            </ol>
                        </li>
                        <li>If multiple entries tie in a tournament, the tie breaker will be which entry had the lowest
                            score in
                            the final round. If they tie in that round, the earlier rounds will be compared (3, 2, 1).
                            If they
                            are still tied, the weekly prize will be split.
                        </li>
                    </ol>

                </p>
            </div>
            <Link className="ContestLink" to="/golf" key='link-mainpage'>Return to the main page</Link>
        </div>
    );
}

export default GolfRules;
