import * as React from 'react';
import PropTypes from "prop-types";

export default class BasketballEventCell extends React.Component {
    render() {
        return (
            <div className="BasketballEventCell">
                <div>
                    <div>{this.props.team1}</div>
                    <div className="BasketballEventCellScore">{this.props.score1}</div>
                </div>
                <div>
                    <div>{this.props.team2}</div>
                    <div className="BasketballEventCellScore">{this.props.score2}</div>
                </div>
            </div>
        );
    }
}

BasketballEventCell.propTypes = {
    team1: PropTypes.string.isRequired,
    team2: PropTypes.string.isRequired,
    score1: PropTypes.number.isRequired,
    score2: PropTypes.number.isRequired,
}
