import * as React from 'react';
import PropTypes from "prop-types";
import EventNotificationDialog from "./EventNotificationDialog";
import {getNewDate} from "../Utils";
import {NotificationsNone, Notifications} from "@mui/icons-material";

export default class FootballEventCell extends React.Component {

    state = {
        notificationDialogOpen: false,
    }
    
    handleNotificationsClick() {
        this.setState({
            notificationDialogOpen: true
        });
    }
    
    selectedTeamClassName(selected) {
        // TODO: move this logic outside this file and have the caller pass in this class for team1Class & team2Class
        return selected ? 'FootballEventCellSelectedTeam' : '';
    } 
    
    handleNotificationsClose() {
        this.setState({
            notificationDialogOpen: false
        });
    }

    teamNameAndRank(teamName, teamRank) {
        return (teamRank ? `(${teamRank}) ` : "") + teamName;
    }
    
    render() {
        return (
            <div className="FootballEventCell">
                <div className="FootballEventCellColumnsContainer">
                    {/* Team & score rows */}
                    <div className="FootballEventCellColumn">
                        <div style={{ color: this.props.team2Color !== undefined? this.props.team2Color : 'inherit'}} className={`FootballEventCellTeam ${this.selectedTeamClassName(this.props.team2Selected)} ${this.props.team2Class}`} dangerouslySetInnerHTML={{__html: this.teamNameAndRank(this.props.team2, this.props.team2Rank) + (this.props.possession === 2 ? ' &#149;' : '')}}/>
                        <div style={{ color: this.props.team1Color !== undefined? this.props.team1Color : 'inherit'}} className={`FootballEventCellTeam ${this.selectedTeamClassName(this.props.team1Selected)} ${this.props.team1Class}`} dangerouslySetInnerHTML={{__html: this.teamNameAndRank(this.props.team1, this.props.team1Rank) + (this.props.possession === 1 ? ' &#149;' : '')}}/>
                    </div>
                    {this.props.started ?
                        <div className="FootballEventCellColumn">
                            <div className="FootballEventCellScore">{this.props.score2}</div>
                            <div className="FootballEventCellScore">{this.props.score1}</div>
                        </div> :
                        <div className="FootballEventCellColumn">
                            <div>{this.props.tv}</div>
                            <div>{this.props.dayFormat(getNewDate(this.props.day))}</div>
                        </div>
                    }
                </div>
                {this.props.status ?
                    <div className="FootballEventCellColumn FootballEventCellStatus">
                        {/* Event status */}
                        {this.props.status}
                    </div> : ''
                }
                {this.props.picksWinning || this.props.picksLosing ?
                    <div className="FootballEventCellColumn FootballEventCellCounts">
                        {/* Pick counts */}
                        {this.props.picksWinning > 0 ? <div className="FootballWinning">{this.props.picksWinning}</div> : ''}
                        {this.props.picksLosing  > 0 ? <div className="FootballLosing">{this.props.picksLosing}</div> : ''}
                    </div> : ''
                }
                {this.props.notificationSupport && this.props.onNotificationUpdate && (!this.props.started || this.props.inProgress) ?
                    <div className="FootballEventCellColumn FootballEventNotification">
                        {this.props.notifications ? 
                            <Notifications sx={{ fontSize: 14 }} onClick={() => this.handleNotificationsClick()}/> :
                            <NotificationsNone sx={{ fontSize: 14 }} onClick={() => this.handleNotificationsClick()}/>
                        }
                        <EventNotificationDialog 
                            open={this.state.notificationDialogOpen}
                            onClose={() => this.handleNotificationsClose()}
                            enabledNotifications={this.props.notifications}
                            onUpdate={this.props.onNotificationUpdate}
                            eventId={`${this.props.eventId}`}
                        />
                    </div> : null
                }
                {/* Event statusNote */}
                {this.props.statusNote ? (
                    <div className="FootballEventCellStatusNote">{this.props.statusNote}</div>
                ) : ''}
            </div>
        );
    }
}

FootballEventCell.propTypes = {
    team1: PropTypes.string.isRequired,
    team2: PropTypes.string.isRequired,
    score1: PropTypes.number.isRequired,
    score2: PropTypes.number.isRequired,
    team1Color: PropTypes.string,
    team2Color: PropTypes.string,
    team1Class: PropTypes.string.isRequired,
    team2Class: PropTypes.string.isRequired,
    started: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    statusNote: PropTypes.string,
    picksWinning: PropTypes.number,
    picksLosing: PropTypes.number,
    myPickStatus: PropTypes.number,
    possession: PropTypes.number,
    notificationSupport: PropTypes.bool,
    notifications: PropTypes.string,
    tv: PropTypes.string,
    day: PropTypes.string,
    dayFormat: PropTypes.func.isRequired,
    team1Selected: PropTypes.bool,
    team2Selected: PropTypes.bool,
    onNotificationUpdate: PropTypes.func,
    eventId: PropTypes.number.isRequired,
    team1Rank: PropTypes.number,
    team2Rank: PropTypes.number,
}
