import React, {Fragment} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import purify from "dompurify";
import {Link} from 'react-router-dom';

function rowClass(row, myEntryName) {
    if (row.EntryName === myEntryName) {
        return 'FootballRowMyEntry';
    }
    return '';
}

function standingsSort(a,b) {
    if (a.Wins !== b.Wins) {
        return b.Wins - a.Wins;
    }
    else if (a.Losses !== b.Losses) {
        return a.Losses - b.Losses;
    }
    else if (a.Points !== b.Points) {
        return b.Points - a.Points;
    }
    return a.EntryName.localeCompare(b.EntryName);
}

export default function StandingsTable(standingsData, week, year, configData) {
    let totalWeeklyWins = 0, totalWeeklyLosses = 0, totalWins = 0, totalLosses = 0, totalPoints = 0;
    return (
        <Fragment>
        <div className="FootballStandingsGridItem">
            <h4>Week {week} Standings</h4>
            <TableContainer component={Paper} className="FootballStandingsGridItemTable">
                <Table size="small" className="standingsTable Pick5DataTable">
                    <TableHead>
                        <TableRow>
                            <TableCell>Entry</TableCell>
                            <TableCell align="right">Wins</TableCell>
                            <TableCell align="right">Losses</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {standingsData
                            .filter((row) => row.Week === week)
                            .sort(standingsSort)
                            .map((row) => {
                                totalWeeklyWins += row.Wins;
                                totalWeeklyLosses += row.Losses;
                                return (
                                <TableRow key={`FootballWeeklyStandingsRow${row.EntryName}`} className={rowClass(row, configData.EntryName)}>
                                    <TableCell align="left">
                                        <div dangerouslySetInnerHTML={{ __html: purify.sanitize(row.EntryName) }} />
                                    </TableCell>
                                    <TableCell align="right">{row.Wins}</TableCell>
                                    <TableCell align="right">{row.Losses}</TableCell>
                                </TableRow>
                            )})
                        }
                        <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell>{totalWeeklyWins}</TableCell>
                            <TableCell>{totalWeeklyLosses}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="FootballPointsAvailableContainer">
                * Points available this week: {configData.PointsThisWeek}
            </div>
            <div className="FootballPointsAvailableContainer">
                * Overall winner points: 400, 300, 200, 175,<br/>
                150, 125, 125, 100, 100 (and 100 no cigar prize)
            </div>
        </div>
        <div className="FootballStandingsGridItem">
            <h4>Overall Standings</h4>
            <TableContainer component={Paper} className="FootballStandingsGridItemTable">
                <Table size="small" className="standingsTable Pick5DataTable">
                    <TableHead>
                        <TableRow>
                            <TableCell>Entry</TableCell>
                            <TableCell align="right">Wins</TableCell>
                            <TableCell align="right">Losses</TableCell>
                            <TableCell align="right">Points</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {standingsData
                            .filter((row) => row.Week === 0)
                            .sort(standingsSort)
                            .map((row) => {
                                totalWins += row.Wins;
                                totalLosses += row.Losses;
                                totalPoints += Number(row.Points);
                                return (
                                    <TableRow key={`FootballOverallStandingsRow${row.EntryName}`} className={rowClass(row, configData.EntryName)}>
                                        <TableCell align="left">
                                            <Link to={`history?year=${year}&entry=${encodeURIComponent(row.EntryName)}`}>
                                                <div dangerouslySetInnerHTML={{ __html: purify.sanitize(row.EntryName) }} />
                                            </Link>
                                        </TableCell>
                                        <TableCell align="right">{row.Wins}</TableCell>
                                        <TableCell align="right">{row.Losses}</TableCell>
                                        <TableCell align="right">{row.Points}</TableCell>
                                    </TableRow>
                                )
                            })}
                        <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell>{totalWins}</TableCell>
                            <TableCell>{totalLosses}</TableCell>
                            <TableCell>{Math.round(totalPoints)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </Fragment>
    );
}
