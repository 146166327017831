import React from 'react';
import {Table, Paper, TableCell, TableRow, TableBody, TableContainer} from "@mui/material";
import purify from "dompurify";
import {footballEntryNameClass, footballResultClass, pickString} from "../Utils";

export default function PicksTable(picksData, configData) {
    return (
        <TableContainer component={Paper} className="FootballStandingsGridItemTable">
            <Table size="small" className="FootballPicksTable">
                <TableBody>
                    {picksData
                        .map((row) => (
                            <TableRow key={`FootballPicksRow${row.EntryName}`}>
                                <TableCell className={footballEntryNameClass(row.EntryName, configData.EntryName)}><div dangerouslySetInnerHTML={{ __html: purify.sanitize(row.EntryName) }} /></TableCell>
                                {row.picks.map((pick) => (
                                    <TableCell className={footballResultClass(pick.Result)} key={`FootballPicksCell${pick.Pick}`}>{pickString(pick.Pick, pick.Spread)}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
