import { useEffect, useRef } from 'react';

export function fetchP5(userName, password, url, stateName, props) {
    
    return fetch(url, {
        headers: {
            "Authorization": "Basic " + btoa(userName + ":" + password),
            "APP-ID": "pick5-app"
        }
    })
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                return data;
            } else if (props && stateName) {
                if (data.data) {
                    let contestState = props.getState();
                    if (contestState[stateName]) {
                        for (const data_name in data.data) {
                            if (data.data.hasOwnProperty(data_name)) {
                                // If the data is an array, assume it has an ID/id column so we can update the local copy
                                const dataObject = data.data[data_name].data;
                                if (Array.isArray(dataObject)) {
                                    if (dataObject.length > 0 && (dataObject[0].hasOwnProperty('ID') || dataObject[0].hasOwnProperty('id'))) {
                                        dataObject.forEach((row) => {
                                            const idx = contestState[stateName].data[data_name].data.findIndex(item => (item.hasOwnProperty('ID') && item.ID === row.ID) || (item.hasOwnProperty('id') && item.id === row.id));
                                            if (idx > -1) {
                                                // Don't just overwrite the existing object, copy the new properties into it and preserve anything added locally
                                                const oldRow = contestState[stateName].data[data_name].data[idx];
                                                contestState[stateName].data[data_name].data[idx] = Object.assign(oldRow, row);
                                            } else {
                                                contestState[stateName].data[data_name].data.push(row);
                                            }
                                        });
                                    }
                                    else if (!Array.isArray(dataObject) || dataObject.length > 0) {
                                        // For non-array objects or arrays that don't have IDs just copy the new data over the old
                                        const oldData = contestState[stateName].data[data_name].data;
                                        contestState[stateName].data[data_name].data = Object.assign(oldData, dataObject);
                                    }
                                }
                                else {
                                    // For non-array objects just copy the new data over the old
                                    if (contestState[stateName].data[data_name] && contestState[stateName].data[data_name].data) {
                                        const oldData = contestState[stateName].data[data_name].data;
                                        contestState[stateName].data[data_name].data = Object.assign(oldData, dataObject);
                                    }
                                }
                            }
                        }
                        props.mergeState({
                            [stateName]: contestState[stateName]
                        });
                    } else {
                        props.mergeState({
                            [stateName]: data
                        });
                    }
                }
            }
            return data;
        });
}

export function postP5(userName, password, url, data) {
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Authorization": "Basic " + btoa(userName + ":" + password),
            "APP-ID": "pick5-app",
            'Content-Type': 'application/json'
        }
    });
}

export function putP5(userName, password, url, data) {
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            "Authorization": "Basic " + btoa(userName + ":" + password),
            "APP-ID": "pick5-app",
            'Content-Type': 'application/json'
        }
    });
}

export function deleteP5(userName, password, url) {
    return fetch(url, {
        method: 'DELETE',
        headers: {
            "Authorization": "Basic " + btoa(userName + ":" + password),
            "APP-ID": "pick5-app",
            'Content-Type': 'application/json'
        }
    });
}

export function getAuth() {
    let auth = localStorage.getItem('pick5#!#auth');
    if (auth && auth.substring(0, 1) === '"') {
        auth = auth.split('"')[1];
    }
    if (auth) {
        auth = auth.split(':');
        const credentials = atob(auth[0]).split(':');
        return {
            userName: credentials[0],
            password: credentials[1],
            group: auth[1]
        };
    }
    
    return null;
}



export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

export function getNewDate(datetime) {
    let d = new Date(datetime + "-00:00");
    if (isValidDate(d)) {
        return d;
    }

    d = new Date(datetime.replace(' ', 'T'));
    if (isValidDate(d)) {
        return d;
    }

    return null;
}

export function fixupTime(hours, minutes, seconds = null) {
    let strHours = "";
    let strMinutes = "";
    let strSeconds = "";
    let strAmPm = "";

    if (hours > 12) {
        strHours = (hours - 12);
    }
    else if (hours === 0) {
        strHours = "12";
    }
    else {
        strHours = hours;
    }

    if (minutes < 10) {
        strMinutes = "0" + minutes;
    }
    else {
        strMinutes = minutes;
    }

    if (hours >= 12) {
        strAmPm = " PM";
    }
    else {
        strAmPm = " AM";
    }

    if (seconds === null) {
        return strHours + ":" + strMinutes + strAmPm;
    }

    if (seconds < 10) {
        strSeconds = '0' + seconds;
    }
    else {
        strSeconds = seconds;
    }

    return strHours + ":" + strMinutes + ":" + strSeconds + strAmPm;
}

export function fixThroughTeeTime(throughText) {
    if (throughText.indexOf(':') > -1) {
        const today = new Date();
        const teeTime = new Date(
            today.getFullYear() + '/' +
            (today.getMonth() + 1) + '/' +
            today.getDate() + ' ' +
            throughText.toLowerCase().replace('am', ' am ').replace('pm', ' pm ').replace('*', '') + 'UTC');
        return fixupTime(teeTime.getHours(), teeTime.getMinutes());
    }
    return throughText;
}

export function getDateString(date) {
    return (date.getMonth() + 1) + "/" + date.getDate() + " " + fixupTime(date.getHours(), date.getMinutes());
}

export function getSimpleDateString(date) {
    return (date.getMonth() + 1) + "/" + date.getDate();
}

/**
 * Call the server API to register for notifications.  If the device & vendor ID aren't set
 * on window, it will simply reset the device badge count to zero.  That occurs when the mobile app
 * is reopened and does an immediate refresh as opposed to when the app loads fresh from being killed
 * and does the full registration.
 * 
 * @param userName
 * @param password
 */
export function callRegisterForNot(userName, password) {
    let regNotUri = "/api/v1/utils/rfn";
    if (window.p5Device && window.p5UID) {
        regNotUri += `?vid=${window.p5UID}&dt=${window.p5Device}` 
    }
    fetchP5(userName, password, regNotUri)
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                return data.error;
            }
        });
}

export function pickString(pick, spread) {
    return pick + ' ' + (spread === null ? '' : (spread >= 0 ? '+' : '') + spread);
}

export function footballResultClass(result) {
    switch (result) {
        case  0: return 'FootballLoss'
        case  1: return 'FootballWin'
        case -1: return 'FootballWinning';
        case -2: return 'FootballLosing'
        case  3: return 'FootballPicked';
        default: return '';
    }
}

export function footballEntryNameClass(entryName, myEntryName) {
    if (entryName === myEntryName) {
        return 'FootballRowMyEntry';
    }
    return '';
}

