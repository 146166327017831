import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {fetchP5, fixThroughTeeTime} from "../Utils";
import Button from "@mui/material/Button";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SortableTable from '../../src/components/SortableTable';
import TableCell from "@mui/material/TableCell";
import Footer from "../components/Footer";

function GolfMakePicks(props) {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [fetchUrl, ] = useState("/api/v1/golf/makepicksdata");
    const [availableGolfers, setAvailableGolfers] = useState([]);
    const [currentPicks, setCurrentPicks] = useState({});
    const [config, setConfig] = useState({});
    const [submitPicksResult,setSubmitPicksResult] = useState("");

    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, fetchUrl, 'golfMakePicks', props)
            .then(data => {
                // Determine if there are multiple courses for the tournament
                const firstCode = data.data.available_golfers.data[0].code;
                const otherCodeGolfer = data.data.available_golfers.data.find((golfer) => golfer.code !== firstCode);
                data.data.config.data[0].multiCourse = (otherCodeGolfer !== undefined);
                setConfig(data.data.config.data[0]);
                setAvailableGolfers(data.data.available_golfers.data);
                if (data.data.current_picks.data.length) {
                    setCurrentPicks(data.data.current_picks.data[0]);
                }
                setIsLoading(false);
            }
        );
    }
    
    useEffect(() => {
        document.title = "Pick 5 Golf Picks";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            fetchData();
        }
    }, []);
    
    function handleSubmitPicksClick() {
        const url = `/api/v1/golf/MakePicks?pick1=${currentPicks.Pick1}&pick2=${currentPicks.Pick2}&pick3=${currentPicks.Pick3}&pick4=${currentPicks.Pick4}`
        fetchP5(props.getState().userName, props.getState().password, url, 'golfMakePicks', props)
            .then(data => {
                setSubmitPicksResult(data.error ? data.error.message : "Success");
                setTimeout(() => {
                    setSubmitPicksResult('');
                }, 5000);
            }
        );
    }
    
    function handleRandomClick() {
        const picks = {...currentPicks};
        [...Array(4).keys()].forEach((pickNum) => {
            const groupGolfers = availableGolfers.filter((golfer) => golfer.Group_Num === pickNum);
            const randomIdx = Math.floor(Math.random() * groupGolfers.length);
            picks[`Pick${Number(pickNum)+1}`] = groupGolfers[randomIdx].Golfer_ID
        });
        setCurrentPicks(picks);
    }

    function renderLoading() {
        return <div>Loading</div>;
    }

    const handlePickChange = (event) => {
        const picks = {...currentPicks};
        const [pickNum,pick] = event.target.value.split(' '); 
        picks[`Pick${Number(pickNum)+1}`] = Number(pick);
        setCurrentPicks(picks);
    };
    
    const handlePickRowClick = (event, golferId) => {
        const picks = {...currentPicks};
        const pickNum = availableGolfers.filter((g) => golferId === g.Golfer_ID)[0]['Group_Num'];
        picks[`Pick${Number(pickNum)+1}`] = Number(golferId);
        setCurrentPicks(picks);
    };
    
    const headerCells = [
        {
            label: 'Name',
            id: 'Name',
            numeric: false,
        },
        {
            label: 'Rank',
            id: 'Rank',
            numeric: true,
        },
        {
            label: 'Tee Time',
            id: 'Tee_Time',
            numeric: false,
        },
        {
            label: 'BPR',
            id: 'Avg_Birdies',
            numeric: true,
        },
        {
            label: 'HPE',
            id: 'Holes_Per_Eagle',
            numeric: true,
        },
        {
            label: 'Cuts',
            id: 'Cuts_Made',
            numeric: true,
        },
        {
            label: 'CC',
            id: 'Consecutive_Cuts',
            numeric: true,
        },
        {
            label: 'GIR',
            id: 'GIR',
            numeric: true,
        }
    ];

    function sortableRowId(row) {
        return row.Golfer_ID;
    }

    if (config.is_admin) {
        headerCells.push({
            label: 'Odds',
            id: 'Odds',
            numeric: true,
        });
    }
    
    function renderPickRow(row, headerCell) {
        let value = headerCell.id === 'Tee_Time' ? fixThroughTeeTime(row[headerCell.id]) : row[headerCell.id];
        if (config.multiCourse && headerCell.id === 'Name' && row.code) {
            value += ` (${row.code})`;
        }
        return (
            <TableCell align="right" key={`sortableTableCell${headerCell.id}-${row.Group_Num}-${row.Golfer_ID}`}>{value}</TableCell>
        );
    }
    
    return (
        <div>
            {isLoading ? renderLoading() : (
                <Fragment>
                    <h3>{config.Name}</h3>
                    <div>
                        <Button onClick={handleSubmitPicksClick}>Submit Picks</Button>
                        <Button onClick={handleRandomClick}>Random!</Button>
                        <div className={`GolfMakePicksSubmitResult ${submitPicksResult === 'Success' ? 'SubmitResultSuccess' : (submitPicksResult === '' ? '' : 'SubmitResultError')}`}>{submitPicksResult}</div>
                    </div>
                    <div className="GolfMakePicksPicksContainer">
                        {[...Array(4).keys()].map((pickNum) => {
                            const groupGolfers = availableGolfers.filter((golfer) => golfer.Group_Num === pickNum);
                                return (
                                    <div className="GolfMakePicksPickContainer" key={`PickContainer${pickNum}`}>
                                        <h4>Pick {pickNum + 1}</h4>
                                        <Select
                                            size="small"
                                            fullWidth
                                            value={currentPicks ? `${pickNum} ` + currentPicks[`Pick${pickNum + 1}`] : '0'}
                                            onChange={handlePickChange}
                                        >
                                            {groupGolfers.map((golfer) =>
                                                    <MenuItem key={`picklist-menuitem-${golfer.Golfer_ID}`}
                                                              value={`${pickNum} ${golfer.Golfer_ID}`}>{golfer.Name}</MenuItem>
                                                )}
                                        </Select>
                                        <SortableTable
                                            rows={groupGolfers}
                                            headCells={headerCells}
                                            defaultOrderBy="Rank"
                                            selected={currentPicks ? [currentPicks[`Pick${pickNum+1}`]] : 0}
                                            multiSelect={false}
                                            onRowSelect={handlePickRowClick}
                                            renderRow={renderPickRow}
                                            selectable={true}
                                            rowKey={sortableRowId}
                                        />
                                    </div>
                                );
                            }
                        )}
                    </div>
                </Fragment>
            )}
            <div className="GolfMakePicksLegend">
                <div>BPR = Birdies per round</div>
                <div>HPE = Holes per eagle</div>
                <div>CC = Consecutive cuts made</div>
                <div>GIR = Greens in regulation percent</div>
            </div>
            <Footer/>
        </div>
    );
}

export default GolfMakePicks;
