import React, {Fragment, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {callRegisterForNot, deleteP5, fetchP5, fixThroughTeeTime, postP5, useInterval} from "../Utils";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {Box, Tab, Tabs, Typography} from "@mui/material";
import PropTypes from "prop-types";
import StandingsTable from './StandingsTable';
import LeaderboardTable from "./LeaderboardTable";
import SmackboardTable from "../components/SmackboardTable";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import purify from "dompurify";
import AddBoxIcon from '@mui/icons-material/AddBox';
import CheckIcon from '@mui/icons-material/Check';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import {grey, green} from '@mui/material/colors';
import Button from "@mui/material/Button";
import Footer from "../components/Footer";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, padding: 0 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const inProgressRefreshInterval = 90;
const notInProgressRefreshInterval = 60 * 10;
const emptyRound = Array(18).fill(null);
class GolferObject {
    constructor(id, name) {
        this.Name = name;
        this.Id = id;
        this.Scores = [[...emptyRound], [...emptyRound], [...emptyRound], [...emptyRound]];
        this.RoundScores = [0, 0, 0, 0];
        this.course_ids = ["0", "0", "0", "0"];
        this.TotalScore = 0;
        this.Points = 0;
        this.Num_Picked = 1;
        this.Picked = false;
        this.eagles = 0;
        this.birdies = 0;
        this.bogies = 0;
        this.Cut = 0;
        this.holes_remaining = 0;
        this.Group_Num = 0;
    }
}

class CourseObject {
    constructor(course) {
        this.id = course.course_id;
        this.par = course.Par;
        this.name = course.Name;
        this.code = course.code;
        this.holes = []; // par value for each hole
        for (let i = 0; i < 18; i++) {
            const key = "Hole" + (i+1);
            this.holes[i] = course[key];
        }
    }
}

function Golf(props) {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [tournamentId, setTournamentId] = useState(0);
    const [currentTab, setCurrentTab] = useState(0);
    const [refreshIn, setRefreshIn] = useState(inProgressRefreshInterval);
    const [refreshInterval, setRefreshInterval] = useState(inProgressRefreshInterval);
    const [fetchUrl, setFetchUrl] = useState("/api/v1/golf/pagedata");
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [entriesData, setEntriesData] = useState([]);
    const [configData, setConfigData] = useState({});
    const [smackData, setSmackData] = useState([]);
    const [standingsData, setStandingsData] = useState([]);
    const [pickedGolfers, setPickedGolfers] = useState({});
    const [watchedGolfers, setWatchedGolfers] = useState([]);
    const [myPicks, setMyPicks] = useState([]);
    const [coursesData, setCoursesData] = useState({});
    const [pick1PicksData, setPick1PicksData] = useState({});
    const [sendNotifications, setSendNotifications] = useState(false);
    const [lastShotNotifications, setLastShotNotifications] = useState({});
    const [smackPostEnabled, setSmackPostEnabled] = useState(true);

    // Non-changing data props: courses, picks, pick1picks, tournaments

    function entriesSortFunction(a,b) {
        const aPoints = a.Points - a.side_action_points;
        const bPoints = b.Points - b.side_action_points;
        if (a.Score !== b.Score) {
            return a.Score - b.Score;
        } else if (aPoints !== bPoints) {
            return bPoints - aPoints;
        } else {
            return a.EntryName.localeCompare(b.EntryName);
        }
    }
    
    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, fetchUrl, 'golf', props)
            .then(data => {
                const config = props.getState().golf.data.config.data[0];
                if (config.status === "IN_PROGRESS") {
                    setRefreshInterval(inProgressRefreshInterval);
                }
                else {
                    setRefreshInterval(notInProgressRefreshInterval);
                }

                setConfigData(config);
                setSmackData(props.getState().golf.data.smack.data);
                setStandingsData(props.getState().golf.data.standings.data);
                setCurrentTab(config.current_round - 1);
                
                if (data.__delta) {
                    const url = new URL(data.__delta);
                    setFetchUrl(url.pathname + url.search);
                }

                if (isLoading && entriesData.length === 0) {
                    if (tournamentId === 0) {
                        setTournamentId(config.current_tournament);
                    }
                    processPicks(props.getState().golf.data.picks.data, config);
                    processCourses(props.getState().golf.data.courses.data);
                    processLeaderboard(props.getState().golf.data.leaderboard.data)
                    processExpandedCookie();
                    processWatchedGolfersCookie(tournamentId || config.current_tournament, props.getState().golf.data.leaderboard.data);
                    processPick1Picks(props.getState().golf.data.pick1picks.data);
                    if (config.is_admin) {
                        if ('Notification' in window) {
                            if (Notification.permission === 'default') {
                                Notification.requestPermission().then(function (result) {
                                    setSendNotifications(result === 'granted');
                                });
                            } else if (Notification.permission === 'granted') {
                                setSendNotifications(true);
                            }
                        }
                    }
                    setIsLoading(false);
                }

                processNotifications(props.getState().golf.data.leaderboard.data);
                setLeaderboardData(props.getState().golf.data.leaderboard.data);
                processStandings(props.getState().golf.data.standings.data);
                processScores(props.getState().golf.data.scores.data);
            });
    };

    useInterval(() => {
        if (refreshInterval) {
            if (refreshIn === 0 || window.p5MobileRefreshNow) {
                fetchData();
                setRefreshIn(refreshInterval);
                if (window.p5MobileRefreshNow) {
                    callRegisterForNot(props.getState().userName, props.getState().password);
                    delete window['p5MobileRefreshNow'];
                }
            }
            else {
                setRefreshIn(refreshIn - 1);
            }
        }
    }, 1000);
    
    useEffect(() => {
        document.title = "Pick 5 Golf";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            fetchData();
        }
    }, [tournamentId]);

    function processNotifications(newLeaderboardData) {
        if (sendNotifications) {
            const notificationToSend = watchedGolfers.reduce((text, golferId) => {
                const newRow = newLeaderboardData.filter((row) => row.Golfer_ID === golferId)[0];
                if (!newRow) {
                    return text;
                }
                const newShot = newRow.last_shot && newRow.last_shot !== "" ? newRow.last_shot : fixThroughTeeTime(newRow.Through);
                if (lastShotNotifications[golferId] !== newShot) {
                    lastShotNotifications[golferId] = newShot;
                    return text + (text !== '' ? '\n' : '') + newRow.Name.substr(newRow.Name.indexOf(' ') + 1) + ': ' + newShot;
                }
                else {
                    return text;
                }
            }, '');
            if (notificationToSend !== '') {
                new Notification(notificationToSend);
            }
            setLastShotNotifications(lastShotNotifications);
        }
    }
    
    function processScores(scoresData) {

        for (const [, golfer] of Object.entries(pickedGolfers)) {
            golfer.birdies = 0;
            golfer.eagles = 0;
            golfer.bogies = 0;
        }

        // Update each golfer object
        scoresData.forEach((scoreRow) => {
            if (pickedGolfers[scoreRow.golfer_ID]) {
                processScore(pickedGolfers[scoreRow.golfer_ID], scoreRow);
            }
        });

        for (const [, golfer] of Object.entries(pickedGolfers)) {
            golfer.TotalScore = golfer.RoundScores[0] +
                golfer.RoundScores[1] +
                golfer.RoundScores[2] +
                golfer.RoundScores[3];
        }

        // Update all entries with the new scores
        entriesData.forEach((entry) => {
            updateEntryScores(entry, 0);
            updateEntryScores(entry, 1);
            updateEntryScores(entry, 2);
            updateEntryScores(entry, 3);
            entry.TotalScore = entry.RoundScores[0] +
                entry.RoundScores[1] +
                entry.RoundScores[2] +
                entry.RoundScores[3];
        });
        
        entriesData.sort(entriesSortFunction);
    }

    function notNan(score, def) {
        return (score === null || isNaN(score) ? def : score);
    }

    function isNaNOrNull(score) {
        return isNaN(score) || (score === null);
    }
    
    // best ball minimum
    function bbMin(s1, s2, s3, s4) {
        if (isNaNOrNull(s1) && isNaNOrNull(s2) && isNaNOrNull(s3) && isNaNOrNull(s4)) {
            return s1;
        }
        return Math.min(notNan(s1, 99), notNan(s2, 99), notNan(s3, 99), notNan(s4, 99));
    }

    function opportunityGone(golfer, round, hole) {
        if (golfer.holes_remaining === 0) {
            return 1;
        }
        else if (golfer.course_ids[round] === '0') {
            return 1;
        }
        else if (isNaN(golfer.Scores[round][hole]) || golfer.Scores[round][hole] === null) {
            // haven't played it yet
            return 0;
        }
        else { // if (golfer.Scores[round][hole] < 0) {  // maybe anything not NaN means opportunity lost...
            return 1;
        }
    }
    
    function updateEntryScores(entry, roundNum) {
        let totalScore = 0;
        entry.GolferBirdieOpportunities[0][roundNum] = 18;
        entry.GolferBirdieOpportunities[1][roundNum] = 18;
        entry.GolferBirdieOpportunities[2][roundNum] = 18;
        entry.GolferBirdieOpportunities[3][roundNum] = 18;
        for (let i = 0; i < 18; i++) {
            entry.BestBall[roundNum][i] = bbMin(pickedGolfers[entry.Pick1].Scores[roundNum][i],
                pickedGolfers[entry.Pick2].Scores[roundNum][i],
                pickedGolfers[entry.Pick3].Scores[roundNum][i],
                pickedGolfers[entry.Pick4].Scores[roundNum][i]);
            const holeScore = notNan(entry.BestBall[roundNum][i], 0);
            totalScore = totalScore + holeScore;
            if (holeScore >= 0) {
                entry.GolferBirdieOpportunities[0][roundNum] -= opportunityGone(pickedGolfers[entry.Pick1], roundNum, i);
                entry.GolferBirdieOpportunities[1][roundNum] -= opportunityGone(pickedGolfers[entry.Pick2], roundNum, i);
                entry.GolferBirdieOpportunities[2][roundNum] -= opportunityGone(pickedGolfers[entry.Pick3], roundNum, i);
                entry.GolferBirdieOpportunities[3][roundNum] -= opportunityGone(pickedGolfers[entry.Pick4], roundNum, i);
            }
            else {
                entry.GolferBirdieOpportunities[0][roundNum]--;
                entry.GolferBirdieOpportunities[1][roundNum]--;
                entry.GolferBirdieOpportunities[2][roundNum]--;
                entry.GolferBirdieOpportunities[3][roundNum]--;
            }
        }
        entry.RoundScores[roundNum] = totalScore;
        entry.BirdieOpportunities[roundNum] = entry.GolferBirdieOpportunities[0][roundNum] +
            entry.GolferBirdieOpportunities[1][roundNum] +
            entry.GolferBirdieOpportunities[2][roundNum] +
            entry.GolferBirdieOpportunities[3][roundNum];
    }
    
    function processScore(golfer, scoreRow) {
        for (let i = 1; i < 19; i++) {
            golfer.Scores[scoreRow.round_number - 1][i-1] = scoreRow['Hole'+i];
        }
        golfer.RoundScores[scoreRow.round_number - 1] = 0;
        golfer.holes_remaining = scoreRow.holes_remaining;
        golfer.course_ids[scoreRow.round_number - 1] = scoreRow.course_id;
        for (let i = 0; i < 18; i++) {
            golfer.RoundScores[scoreRow.round_number - 1] += (golfer.Scores[scoreRow.round_number - 1][i] === undefined ? 0 : golfer.Scores[scoreRow.round_number - 1][i]);
        }
        
        golfer.birdies += scoreRow.birdies;
        golfer.eagles += scoreRow.eagles;
        golfer.bogies += scoreRow.bogies;
    }
    
    function processCourses(courses) {
        courses.forEach((course) => {
            coursesData[course.course_id] = new CourseObject(course);
        });
        setCoursesData({...coursesData});
    }
    
    function processLeaderboard(leaderboard) {
        // mark each picked golfer on the leaderboard
        leaderboard
            .filter((golfer) => pickedGolfers[golfer.Golfer_ID] !== undefined)
            .forEach((golfer) => {
                golfer.myPick = myPicks.includes(golfer.Golfer_ID);
                golfer.picked = true;
            });
    }
    
    function processStandings(standings) {
        // Update any entry properties that may have changed
        const entries = entriesData;
        standings
            .filter((row) => row.Tournament_ID !== 0)
            .forEach((row) => {
                entries
                    .filter((entry) => entry.EntryName === row.EntryName)
                    .forEach((entry) => {
                        entry.Points = Number(row.Points);
                        entry.Score = Number(row.Score);
                        entry.golfers_making_cut = Number(row.golfers_making_cut);
                        entry.side_action = row.side_action;
                        entry.side_action_score = Number(row.side_action_score);
                        entry.holes_remaining = Number(row.holes_remaining);
                        entry.side_action_points = Number(row.side_action_points);
                    });
            });
        setEntriesData(entries.sort(entriesSortFunction));
    }
    
    // Create a simple mapping of golferId and owner name
    function processPick1Picks(picks) {
        let pickObject = {};
        picks.forEach((pick) => {
            pickObject[pick.pick] = pick.entry_name
        });
        setPick1PicksData(pickObject);
    }
    
    function processPicks(picks, config) {
        const entries = entriesData;
        picks.forEach((pickRow) => {
            entries.push(
                {
                    ...pickRow,
                    'Expanded': false,
                    'RoundScores': [0, 0, 0, 0],
                    'BirdieOpportunities': [0,0,0,0],
                    'GolferBirdieOpportunities': [[], [], [], []],
                    'TotalScore': 0,
                    'BestBall': [[], [], [], []],
                }
            );
            if (!pickedGolfers[pickRow.Pick1]) {
                pickedGolfers[pickRow.Pick1] = new GolferObject(pickRow.Pick1, pickRow.Pick1Name);
            } else {
                pickedGolfers[pickRow.Pick1].Num_Picked++;
            }
            if (!pickedGolfers[pickRow.Pick2]) {
                pickedGolfers[pickRow.Pick2] = new GolferObject(pickRow.Pick2, pickRow.Pick2Name);
            } else {
                pickedGolfers[pickRow.Pick2].Num_Picked++;
            }
            if (!pickedGolfers[pickRow.Pick3]) {
                pickedGolfers[pickRow.Pick3] = new GolferObject(pickRow.Pick3, pickRow.Pick3Name);
            } else {
                pickedGolfers[pickRow.Pick3].Num_Picked++;
            }
            if (!pickedGolfers[pickRow.Pick4]) {
                pickedGolfers[pickRow.Pick4] = new GolferObject(pickRow.Pick4, pickRow.Pick4Name);
            } else {
                pickedGolfers[pickRow.Pick4].Num_Picked++;
            }
            if (config.EntryName === pickRow.EntryName) {
                //myEntry = pickRow;
                myPicks.push(pickRow.Pick1, pickRow.Pick2, pickRow.Pick3, pickRow.Pick4);
                setMyPicks([...myPicks]);
                pickedGolfers[pickRow.Pick1].Picked = true;
                pickedGolfers[pickRow.Pick2].Picked = true;
                pickedGolfers[pickRow.Pick3].Picked = true;
                pickedGolfers[pickRow.Pick4].Picked = true;
            }
        });
        setEntriesData(entries);
        setPickedGolfers({...pickedGolfers});
    }
    
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleTournamentChange = (event) => {
        const url = new URL(window.location.origin + fetchUrl);
        url.searchParams.set('tid', event.target.value);
        url.searchParams.delete('ts');
        delete(props.getState().golf);
        setFetchUrl(url.pathname + url.search);
        setTournamentId(event.target.value);
        setEntriesData([]);
        setLeaderboardData([]);
        setIsLoading(true);
    }

    function handleLeaderboardRowClick(row) {
        const golferId = Number(row.currentTarget.getAttribute('golferid'));
        const wIdx = watchedGolfers.indexOf(golferId);
        if (wIdx > -1) {
            watchedGolfers.splice(wIdx,1)
            setWatchedGolfers([...watchedGolfers]);
            saveWatchedGolfersCookie([...watchedGolfers], tournamentId);
        }
        else {
            const newWatchedGolfers = [...watchedGolfers, golferId];
            setWatchedGolfers(newWatchedGolfers);
            saveWatchedGolfersCookie(newWatchedGolfers, tournamentId);
        }
    }
    
    function handleSmackPostMessage(message, onSuccess) {
        setSmackPostEnabled(false);
        postP5(props.getState().userName, props.getState().password, '/api/v1/golf/smack', {
            'Message': message
        })
            .then(response => response.json())
            .then(() => {
                fetchData();
                setSmackPostEnabled(true);
                onSuccess();
            });
    }
    
    function handleSmackDeleteMessage(messageId) {
        deleteP5(props.getState().userName, props.getState().password, '/api/v1/golf/smack?id=' + messageId)
            .then(response => response.json())
            .then(() => {
                setSmackData([...smackData.filter((message) => message.ID !== Number(messageId))]);
            });
    }
    
    function renderLoading() {
        return <div>Loading</div>;
    }
    
    function renderGolfData() {
        return (<Fragment>
            <Box className="GolfScoresBox">
                <div>
                    {renderHeaderBar()}
                    {renderScoreGrid()}
                </div>
                <div className="GolfStatsContainer">
                    {renderWatchedGolfers()}
                    {renderGolferStats()}
                    {configData.pick1golf_entry_id && renderPick1Picks()}
                </div>
            </Box>
            {renderStandingsGrid()}
            </Fragment>)
    }
    
    function renderStandingsGrid() {
        return (<div className="GolfStandingsGridContainer">
            {renderStandings()}
            {renderLeaderboard()}
            <SmackboardTable
                smackboardData={smackData}
                myEntryName={configData.EntryName}
                onPostMessage={handleSmackPostMessage}
                onDeleteMessage={handleSmackDeleteMessage}
                postButtonEnabled={smackPostEnabled}
            />
            {renderSideAction()}
        </div>);
    }
    
    function renderStandings() {
        return StandingsTable(standingsData, configData);
    }

    function renderLeaderboard() {
        let projectedCut;
        if (configData.cut_round === configData.current_round) {
            projectedCut = configData.projected_cut;
        }
        return LeaderboardTable(leaderboardData, projectedCut, handleLeaderboardRowClick); 
    }

    function watchedRowClassName(row) {
        // If the golfer is one of "my" picks, try and color the row based on my best "best-ball" score for the hole he's on
        let currentHole
        if (row.last_shot) {
            let currentHoleIdx = row.last_shot.indexOf('Hole ');
            if (currentHoleIdx > -1) {
                currentHole = Number(row.last_shot.substring(5, 7));
            }
        }
        else if (row.Through) {
            const fixedThrough = row.Through.replace('*', '');
            if (!isNaN(fixedThrough)) {
                currentHole = Number(fixedThrough);
            }
        }

        if (currentHole && myPicks.includes(row.Golfer_ID)) {
            const myEntry = entriesData.filter((entry) => entry.EntryName === configData.EntryName).pop();
            if (myEntry && myEntry.BestBall && myEntry.BestBall[configData.current_round - 1]) {
                return golfScoreClass(myEntry.BestBall[configData.current_round - 1][currentHole - 1] || 0);
            }
        }

        return '';
    }
    
    function pickedGolfersArray() {
        let golfersArray = [];
        for (const [, golfer] of Object.entries(pickedGolfers)) {
            golfersArray.push(golfer);
        }
        return golfersArray.sort(pickedGolferSortFn);
    }
    
    function pickedGolferSortFn(a, b) {
        if (a.Num_Picked !== b.Num_Picked) {
            return b.Num_Picked - a.Num_Picked;
        }
        if (a.TotalScore !== b.TotalScore) {
            return a.TotalScore - b.TotalScore;
        }
    }
    
    function renderGolferStats() {
        return (
            <Paper className="GolfGolferStatsPaper">
                <TableContainer className="GolfGolferStatsTableContainer">
                    <Table size="small" stickyHeader className="GolfGolferStatsTable Pick5DataTable">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Picked Count</TableCell>
                                <TableCell>Score</TableCell>
                                <TableCell>Birdies</TableCell>
                                <TableCell>Eagles</TableCell>
                                <TableCell>Bogies</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pickedGolfersArray().map((golfer) => {
                                return (
                                    <TableRow key={`GolferStatsRow${golfer.Id}`} className={myPicks.includes(golfer.Id) ? 'GolfLeaderboardRowMyPick' : ''}>
                                        <TableCell>{golfer.Name}</TableCell>
                                        <TableCell>{golfer.Num_Picked}</TableCell>
                                        <TableCell>{golfer.TotalScore}</TableCell>
                                        <TableCell>{golfer.birdies}</TableCell>
                                        <TableCell>{golfer.eagles}</TableCell>
                                        <TableCell>{golfer.bogies}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    }
    
    function renderPick1Picks() {
        return (
            <Fragment>
                <h4 className="GolfPick1PicksTableHeader">Pick1 Contest</h4>
                <Table size="small" className="Pick5DataTable GolfPick1PicksTable">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Score</TableCell>
                            <TableCell>Through</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leaderboardData.filter((row) => pick1PicksData.hasOwnProperty(row.Golfer_ID)).map((row) => {
                            return (
                                <TableRow key={`Pick1TableRow${row.Golfer_ID}`}>
                                    <TableCell>{`(${row.Position || ''}) ${row.Name}`}</TableCell>
                                    <TableCell>{row.Score}</TableCell>
                                    <TableCell>{fixThroughTeeTime(row.Through)}</TableCell>
                                    <TableCell>{pick1PicksData[row.Golfer_ID]}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Fragment>
        );
    }
    
    function renderWatchedGolfers() {
        return (
            <Table size="small" className="GolfWatchedGolfersTable Pick5DataTable">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Last Shot</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {watchedGolfers.map((golferId) => 
                        leaderboardData
                            .filter((row) => row.Golfer_ID === golferId)
                            .map((row) => {
                                const lastShotString = row.last_shot && row.last_shot !== "" ? row.last_shot : fixThroughTeeTime(row.Through);
                                return <TableRow className={watchedRowClassName(row)} onClick={handleLeaderboardRowClick} golferid={row.Golfer_ID} key={`WatchedGolfersRow${row.Golfer_ID}`}>
                                    <TableCell>{`(${row.Position ? row.Position : ''}) ${row.Name}`}</TableCell>
                                    <TableCell>{lastShotString}</TableCell>
                                </TableRow>
                            }
                        )
                    )}
                </TableBody>
            </Table>
            );
    }
    
    function sideActionSort(a, b) {
        if (a.side_action_score !== b.side_action_score) {
            return a.side_action_score - b.side_action_score;
        }
        return a.EntryName.localeCompare(b.EntryName);
    }
    
    function handleOptInButtonClick() {
        fetchP5(props.getState().userName, props.getState().password, '/api/v1/golf/sideactionoptin')
            .then(() => fetchData());
    }
    
    function renderSideAction() {
        return (
            <div className="GolfStandingsGridItem">
                <h4 className="GolfSideActionTableHeader">Side Action</h4>
                <div className="GolfSideActionContainer">
                {[4,3,2,1].map((groupNum) => {
                    const groupEntries = standingsData.filter((row) => row.Tournament_ID === tournamentId && row.golfers_making_cut === groupNum);
                    if (groupEntries.length === 0) {
                        return '';
                    }
                    return (
                        <Table size="small" className="Pick5DataTable" key={'SideActionTableGroup'+groupNum}>
                            <TableHead>
                                <TableRow>
                                    <TableCell><div className={"GolfSideActionGroupNum GolfScoreRowEntryCutCount GolfScoreRowEntryCutCountColor"+groupNum}>{groupNum}</div></TableCell>
                                    <TableCell>In</TableCell>
                                    <TableCell>Score</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groupEntries.sort(sideActionSort).map((entry) => {
                                    return (
                                        <TableRow key={'sideActionRow'+entry.ID}>
                                            <TableCell>
                                                <div dangerouslySetInnerHTML={{ __html: purify.sanitize(entry.EntryName) }}/>
                                            </TableCell>
                                            <TableCell>
                                                {!entry.side_action && entry.EntryName === configData.EntryName && configData.side_action_open ? 
                                                    (
                                                        <Button size="small" className="SideActionOptInButton" onClick={handleOptInButtonClick}>Opt In</Button>
                                                    ) :
                                                    (
                                                        entry.side_action > 0 ? <CheckIcon sx={{ fill: green[500], fontSize:14 }}/> : ''
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell>{entry.side_action_score}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    );
                })}
                </div>
            </div>
        );
    }

    function renderScoreGrid() {
        return (
            <div className="GolfScoreGridContainer">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
                        <Tab label="Round 1"/>
                        <Tab label="Round 2"/>
                        <Tab label="Round 3"/>
                        <Tab label="Round 4"/>
                    </Tabs>
                </Box>
                <TabPanel value={currentTab} index={0}>{renderScoreTab(1)}</TabPanel>
                <TabPanel value={currentTab} index={1}>{renderScoreTab(2)}</TabPanel>
                <TabPanel value={currentTab} index={2}>{renderScoreTab(3)}</TabPanel>
                <TabPanel value={currentTab} index={3}>{renderScoreTab(4)}</TabPanel>
            </div>
        );
    }
    
    function renderEntryNameCell(roundNum, entry) {
        return (
            <TableCell className="GolfScoreRowEntryNameCell">
                <div onClick={() => handleEntryExpandClick(entry)} className="GolfScoreRowEntryNameCellContainer">
                    {entry.Expanded ? <RemoveOutlinedIcon sx={{ color: grey[500] }} className="GolfScoreRowExpandIcon"/> : <AddBoxIcon sx={{ color: grey[500] }} className="GolfScoreRowExpandIcon"/>}
                    <div dangerouslySetInnerHTML={{ __html: purify.sanitize(entry.EntryName) }} className="GolfScoreRowName"/>
                    <div className={"GolfScoreRowEntryCutCount GolfScoreRowEntryCutCountColor"+entry.golfers_making_cut}>{entry.golfers_making_cut}</div>
                </div>
            </TableCell>
        );
    }
    
    function golfScoreClass(score, courseId, holeNum) {
        switch (score) {
            case null: return '';
            case 0:  return 'GolfColorPar';
            case 1:  return 'GolfColorBogey';
            case 2:  return 'GolfColorDoubleBogey';
            case -1: return 'GolfColorBirdie';
            case -2: {
                if (courseId && holeNum) {
                    return coursesData[courseId].holes[holeNum] === 3 ? 'GolfColorAce' : 'GolfColorEagle';
                }
                else {
                    return 'GolfColorEagle';
                }
            }
            case -3: return 'GolfColorDoubleEagle';
            default:
                return score > 0 ? 'GolfColorDoubleBogey' : 'GolfColorDoubleEagle';
        }
    }
    
    function renderScoreRow(roundNum, entryId, golferId, scores, roundScore, holesRemaining, birdieOpportunitiesRemaining, totalScore, courseId) {
        return (
            <Fragment>
                {scores.map((score,index) => 
                    <TableCell key={roundNum+'E'+entryId+'score'+index} className={golfScoreClass(score, courseId, index)}>
                        {score !== null ? score : ''}
                    </TableCell>)
                }
                <TableCell key={roundNum+'E'+entryId+'roundScore'+golferId}>{roundScore}</TableCell>
                <TableCell key={roundNum+'E'+entryId+'holesRemaining'+golferId}>{holesRemaining}</TableCell>
                <TableCell key={roundNum+'E'+entryId+'BOR'+golferId}>{birdieOpportunitiesRemaining}</TableCell>
                <TableCell key={roundNum+'E'+entryId+'totalScore'+golferId}>{totalScore}</TableCell>
            </Fragment>
        );
    }
    
    function renderScoreTab(roundNum) {
        return (             
            <TableContainer component={Paper} className="GolfScoreTableContainer">
                <Table size="small" className="GolfScoreTable Pick5DataTable">
                    <TableHead>
                        <TableRow key={roundNum+'headerRow'}>
                            <TableCell key={roundNum+'emptyCell'}/>
                            {[...Array(18).keys()].map((hole) => {
                                return <TableCell key={roundNum+'headerHole'+hole}>{hole+1}</TableCell>
                            })}
                            <TableCell key={roundNum+'headerRound'}>Round</TableCell>
                            <TableCell key={roundNum+'headerHR'}>HR</TableCell>
                            <TableCell key={roundNum+'headerBOR'}>BOR</TableCell>
                            <TableCell key={roundNum+'headerTotal'} align="right">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {entriesData.map((entry) => {
                            return (
                                <Fragment key={entry.id+'fragment'}>
                                    <TableRow key={roundNum+'E'+entry.id+'bbRowTop'}>
                                        {renderEntryNameCell(roundNum, entry)}
                                        {!entry.Expanded && 
                                            renderScoreRow(roundNum, entry.id, 1, entry.BestBall[roundNum-1], entry.RoundScores[roundNum-1], entry.holes_remaining, entry.BirdieOpportunities[roundNum-1], entry.TotalScore)
                                        }
                                    </TableRow>
                                    {entry.Expanded && (<Fragment>
                                            {[...Array(4).keys()].map((index) => {
                                                const golfer = pickedGolfers[entry['Pick'+(index+1)]];
                                                let courseCode = '';
                                                if (Object.keys(coursesData).length > 1 && coursesData[golfer.course_ids[configData.current_round - 1]]) {
                                                    courseCode = ` (${coursesData[golfer.course_ids[configData.current_round - 1]].code})`;
                                                }
                                                return (
                                                    <TableRow key={roundNum+'E'+entry.id+'nameRow'+golfer.Id}>
                                                        <TableCell key={roundNum+'E'+entry.id+'golferNameCell'+golfer.Id}>{golfer.Name}{courseCode}</TableCell>
                                                        {renderScoreRow(roundNum, 
                                                            entry.id,
                                                            golfer.Id,
                                                            golfer.Scores[roundNum-1],
                                                            golfer.RoundScores[roundNum-1],
                                                            golfer.holes_remaining, 
                                                            entry.GolferBirdieOpportunities[index][roundNum-1],
                                                            golfer.TotalScore,
                                                            golfer.course_ids[roundNum-1]
                                                        )}
                                                    </TableRow>
                                                    )
                                                })
                                            }
                                            <TableRow key={roundNum+'E'+entry.id+'bbRowBottom'}>
                                                <TableCell key={roundNum+'E'+entry.id+'bbRowLabel'}>Best Ball</TableCell>
                                                {renderScoreRow(roundNum, entry.id, 2, entry.BestBall[roundNum-1], entry.RoundScores[roundNum-1], entry.holes_remaining, entry.BirdieOpportunities[roundNum-1], entry.TotalScore)}
                                            </TableRow>
                                        </Fragment>
                                    )}
                                </Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
    
    function handleRefreshNowClick() {
        setRefreshIn(0);
    }
    
    function handleEntryExpandClick(entry) {
        const entries = entriesData;
        entries.filter((e) => e.id === entry.id).forEach((e) => e.Expanded = !e.Expanded);
        setEntriesData([...entries]);
        saveExpandedCookie();
    }

    function saveExpandedCookie() {
        const expanded = entriesData
            .filter((entry) => entry.Expanded)
            .reduce((expanded, entry) => expanded += entry.id + ',', '');
        localStorage.setItem("golf_expanded", expanded);
    }

    function processExpandedCookie() {
        const expandedCookie = localStorage.getItem("golf_expanded");
        if (expandedCookie) {
            const entryIds = expandedCookie.split(",");
            entryIds.forEach((id) =>
                entriesData
                    .filter((e) => e.id === Number(id))
                    .forEach((e) => e.Expanded = true)
            );
        }
    }

    function saveWatchedGolfersCookie(golfers, currentTournament) {
        if (golfers.length > 0) {
            let cookieVal = golfers.reduce((cookieStr, golferId) =>
                cookieStr ? cookieStr + ',' + golferId : golferId, '');
            localStorage.setItem('golf_watched_golfers', currentTournament + ":" + cookieVal);
        }
    }

    function processWatchedGolfersCookie(currentTournament, leaderboardData) {
        const watchedCookie = localStorage.getItem('golf_watched_golfers');
        if (watchedCookie) {
            const cookieBits = watchedCookie.split(':');
            if (cookieBits.length < 2 ) {
                setWatchedGolfers([...myPicks]);
                saveWatchedGolfersCookie(myPicks, currentTournament);
                return;
            }
            if (Number(cookieBits[0]) !== currentTournament) {
                setWatchedGolfers([...myPicks]);
                return;
            }
            const watchedIds = cookieBits[1].split(',');
            const newLastShotNotifications = lastShotNotifications;
            watchedIds.forEach((watchedId) =>
                leaderboardData
                    .filter((row) => row.Golfer_ID === Number(watchedId))
                    .forEach((row) => {
                        row.Watched = true;
                        // also setup the last notifications
                        newLastShotNotifications[row.Golfer_ID] = row.last_shot && row.last_shot !== "" ? row.last_shot : fixThroughTeeTime(row.Through);                            
                        })
            );
            setLastShotNotifications(newLastShotNotifications);
            setWatchedGolfers(watchedIds.map((id) => Number(id)));
        }
        else {
            setWatchedGolfers([...myPicks]);
            saveWatchedGolfersCookie(myPicks, currentTournament);
        }
    }
    
    function renderHeaderBar() {
        return <div className="GolfHeaderBar">
            <div className="GolfHeaderBarTournamentLabel">Tournament:</div> 
            <Select
                size="small"
                value={tournamentId}
                fullWidth
                onChange={handleTournamentChange}
                className="GolfHeaderBarTournamentSelect"
            >
                {props.getState().golf.data.tournaments.data.map(( tournament ) => {
                    return <MenuItem key={'tournament-menuitem-'+ tournament.ID} value={tournament.ID}>{tournament.name}</MenuItem>;
                })}
            </Select>
            <div className="GolfHeaderStatus">
                <div>Status: {configData.status}</div>
                {refreshInterval ? <div className="GolfRefreshIn" onClick={handleRefreshNowClick}>Refresh In: {refreshIn} seconds</div> : ''}
            </div>
        </div>;
    }
    
    return (
        <div className="Golf">
            <h3>
                Pick 5 Golf
            </h3>
            <div>
                <Link className="ContestLink" to="/accountsettings" key='link-accountsettings'>Account Settings</Link>
                <Link className="ContestLink" to="makepicks" key='link-golfmakepicks'>Make Picks</Link>
                <Link className="ContestLink" to="rules" key='link-golfrules'>Rules</Link>
            </div>
            {isLoading ? renderLoading() : renderGolfData()}
            <Footer/>
        </div>
    );
}

export default Golf;