import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {
    deleteP5,
    fetchP5,
    postP5,
} from "../Utils";
import Footer from "../components/Footer";
import SmackboardTable from "../components/SmackboardTable";
import StandingsTable from "./StandingsTable";
import ScoresTable from "./ScoresTable";
import TeamsTable from "./TeamsTable";
import WeekResultsTable from "./WeekResultsTable";

const url = new URL(window.location.origin + "/api/v1/football/footballwins_pagedata");
const params = new URLSearchParams(window.location.search);

if (params.get('week')) {
    url.searchParams.append('week', params.get('week'));
}
if (params.get('year')) {
    url.searchParams.append('year', params.get('year'));
}
const defaultUrl = url.toString();

function FootballWins(props) {
    let navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const [isLoading, setIsLoading] = useState(true);
    const [fetchUrl, setFetchUrl] = useState(defaultUrl);
    const [configData, setConfigData] = useState({});
    const [smackData, setSmackData] = useState([]);
    const [scoresData, setScoresData] = useState([]);
    const [teamsData, setTeamsData] = useState([]);
    const [weekResultsData, setWeekResultsData] = useState([]);
    const [standingsData, setStandingsData] = useState([]);
    const [year, setYear] = useState(params.get('year') || 0);
    const [week, setWeek] = useState(params.get('week') || 0);
    const [smackPostEnabled, setSmackPostEnabled] = useState(true);

    function teamResult(score1, score2, inProgress, started) {
        if (!started) {
            return null;
        }
        else if (inProgress) {
            return score1 > score2 ? -1 : -2;
        }
        else {
            return score1 > score2 ? 1 : 0;
        }
    }
    
    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, fetchUrl, 'footballwins', props)
            .then(data => {
                const config = props.getState().footballwins.data.config.data[0];
                setConfigData(config);
                setWeek(week || config.week);
                setYear(year || config.year);
                setSmackData(props.getState().footballwins.data.smack.data);
                setStandingsData(props.getState().footballwins.data.standings.data);
                setScoresData(props.getState().footballwins.data.scores.data);
                setTeamsData(props.getState().footballwins.data.teams.data);
                const weekResults = props.getState().footballwins.data.scores.data.reduce((acc, val) => {
                        let result = teamResult(val.score1, val.score2, val.inProgress, val.started);
                        let idx = acc.findIndex((a) => a.EntryName === val.EntryName1);
                        if (idx < 0) {
                            idx = acc.push({
                                EntryName: val.EntryName1,
                                Wins: 0,
                                Losses: 0,
                                Teams: [],
                            }) - 1;
                        }
                        acc[idx].Wins += result === 1 ? 1 : 0;
                        acc[idx].Losses += result === 0 ? 1 : 0;
                        acc[idx].Teams.push({
                            Team: val.team1,
                            Result: result,
                            Day: val.day,
                        });
                        
                        result = teamResult(val.score2, val.score1, val.inProgress, val.started);
                        idx = acc.findIndex((a) => a.EntryName === val.EntryName2);
                        if (idx < 0) {
                            idx = acc.push({
                                EntryName: val.EntryName2,
                                Wins: 0,
                                Losses: 0,
                                Teams: [],
                            }) - 1;
                        }
                        acc[idx].Wins += result === 1 ? 1 : 0;
                        acc[idx].Losses += result === 0 ? 1 : 0;
                        acc[idx].Teams.push({
                            Team: val.team2,
                            Result: result,
                            Day: val.day,
                        });                        
                        return acc;
                    }, []);
                setWeekResultsData(weekResults);
                if (isLoading) {
                    setIsLoading(false);
                }
            });
    };

    useEffect(() => {
        document.title = "Pick 5 Football Wins";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            fetchData();
        }
    }, [week, year]);

    function renderLoading() {
        return <div>Loading</div>;
    }

    function renderFootballWinsData() {
        return ( 
            <div className="FootballWinsDataContainer">
                <div className="FootballWinsDataRow">
                    {WeekResultsTable(weekResultsData, configData)}
                    {TeamsTable(teamsData, configData)}
                </div>
                <div className="FootballWinsDataRow">
                    {StandingsTable(standingsData, week, year, configData)}
                    {ScoresTable(scoresData, week, configData, teamResult)}
                    <SmackboardTable
                        smackboardData={smackData}
                        myEntryName={configData.EntryName}
                        onPostMessage={handleSmackPostMessage}
                        onDeleteMessage={handleSmackDeleteMessage} 
                        postButtonEnabled={smackPostEnabled}
                    />
                </div>
            </div>
        );
    }

    function handleSmackPostMessage(message, onSuccess) {
        setSmackPostEnabled(false);
        postP5(props.getState().userName, props.getState().password, '/api/v1/football/footballwins_smack', {
            'Message': message
        })
            .then(response => response.json())
            .then(() => {
                fetchData();
                setSmackPostEnabled(true);
                onSuccess();
            });
    }

    function handleSmackDeleteMessage(messageId) {
        deleteP5(props.getState().userName, props.getState().password, '/api/v1/football/footballwins_smack?id=' + messageId)
            .then(response => response.json())
            .then(() => {
                setSmackData([...smackData.filter((message) => message.ID !== Number(messageId))]);
            });
    }

    function changeWeek(newWeek) {
        const url = new URL(window.location.origin + "/api/v1/football/footballwins_pagedata");
        url.searchParams.append('week', newWeek);
        url.searchParams.append('year', year);
        setFetchUrl(url.toString());
        delete(props.getState().footballwins);
        setWeek(newWeek);
        setIsLoading(true);
    }

    return (
        <div className="FootballWins">
            <h3>
                Pick 5 NFL Wins Week {week}
            </h3>
            <div>
                <Link className="ContestLink" to="rules" key='link-footballwinsrules'>Rules</Link>
                {[...Array(18).keys()].map((linkWeek) => {
                    return <Link key={"link-week-"+linkWeek} className="ContestLink" to="." onClick={() => changeWeek(linkWeek+1)}>Week {linkWeek+1}</Link>
                })}
            </div>
            {isLoading ? renderLoading() : renderFootballWinsData()}
            <Footer/>
        </div>
    );
}

export default FootballWins;