import React from 'react';
import {Table, Paper, TableCell, TableRow, TableBody, TableContainer} from "@mui/material";
import purify from "dompurify";
import {footballEntryNameClass, footballResultClass} from "../Utils";

function weekResultsSort(a,b) {
    if (a.Wins !== b.Wins) {
        return b.Wins - a.Wins;
    }
    else if (a.Losses !== b.Losses) {
        return a.Losses - b.Losses;
    }
    else {
        return a.EntryName.localeCompare(b.EntryName);
    }
}

function teamSort(a, b) {
    if (a.day !== b.day) {
        return a.day.localeCompare(b.day);
    }
    else {
        return a.Team.localeCompare(b.Team);
    }
}

export default function WeekResultsTable(weekResults, configData) {
    return (
        <TableContainer component={Paper} className="FootballWinsWeekResultsTableContainer">
            <Table size="small" className="FootballWinsWeeklyResultsTable">
                <TableBody>
                    {weekResults
                        .sort(weekResultsSort)
                        .map((row) => (
                            <TableRow key={`FootballWinsWeeklyResultsRow${row.EntryName}`}>
                                <TableCell className={footballEntryNameClass(row.EntryName, configData.EntryName)}><div dangerouslySetInnerHTML={{ __html: purify.sanitize(row.EntryName) }} /></TableCell>
                                {row.Teams.sort(teamSort).map((team) => (
                                    <TableCell className={footballResultClass(team.Result)} key={`FootballWeeklyResultsCell${team.Team}`}>{team.Team}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
