import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {fixThroughTeeTime} from '../Utils';
import {Fragment} from "react";

function leaderboardSort(a,b) {
    if (!isNaN(a.sort_order) && (a.sort_order !== b.sort_order)) {
        if (a.sort_order > b.sort_order) {
            return 1;
        }
        else {
            return -1;
        }
    }
    if (a.Score !== b.Score) {
        const aScore = a.Score === 'E' ? 0 : Number(a.Score);
        const bScore = b.Score === 'E' ? 0 : Number(b.Score);
        if (isNaN(aScore)) {
            return 1;
        }
        if (isNaN(bScore)) {
            return -1;
        }
        return aScore - bScore;
    }
    a.Name.localeCompare(b.Name);
}

function scoreToNumber(score) {
    if (score === 'E') {
        return 0;
    }
    else {
        return Number(score);
    }
}

export default function LeaderboardTable(leaderboardData, projectedCut, handleOnRowClick) {
    if (!isNaN(projectedCut) && leaderboardData.length) {
        const cutLineIdx = leaderboardData.findIndex((row) => row.cutLine);
        if (cutLineIdx >= 0) {
            leaderboardData.splice(cutLineIdx, 1);
        }
        let cutSortOrder;
        leaderboardData
            .sort((a,b) => leaderboardSort(a,b))
            .forEach((row) => {
                if (scoreToNumber(row.Score) === (projectedCut+1) && cutSortOrder === undefined) {
                    cutSortOrder = row.sort_order - 0.5;
                }
            });
        if (cutSortOrder !== undefined) {
            leaderboardData.push({
                sort_order: cutSortOrder,
                cutLine: true,
            });
        }
    }
    return (
        <div className="GolfStandingsGridItem">
            <h4>Leaderboard</h4>
            <TableContainer component={Paper} className="GolfStandingsGridItemTable">
                <Table size="small" aria-label="a dense table" className="GolfLeaderboardTable Pick5DataTable">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Score</TableCell>
                            <TableCell>Through</TableCell>
                            <TableCell>Today</TableCell>
                            <TableCell>Group</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leaderboardData
                            .sort((a,b) => leaderboardSort(a,b))
                            .map((row) => (
                                <TableRow
                                    key={row.Name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                                    className={row.myPick ? 'GolfLeaderboardRowMyPick' : row.picked ? 'GolfLeaderboardRowPicked' : ''}
                                    onClick={handleOnRowClick}
                                    golferid={row.Golfer_ID}
                                >
                                    {row.cutLine ? (
                                        <TableCell className="GolfLeaderboardRowProjectedCut" colSpan="5">Projected Cut</TableCell>
                                    ) :
                                        (<Fragment>
                                            <TableCell>({row.Position}) {row.Name}</TableCell>
                                            <TableCell>{row.Score}</TableCell>
                                            <TableCell>{fixThroughTeeTime(row.Through)}</TableCell>
                                            <TableCell>{row.Round_Score}</TableCell>
                                            <TableCell>{row.Group_Num}</TableCell>
                                            </Fragment>
                                        )}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
