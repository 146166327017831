import React from 'react';
import {Table, Paper, TableCell, TableRow, TableBody, TableContainer} from "@mui/material";
import purify from "dompurify";
import {footballEntryNameClass} from "../Utils";
import TableHead from "@mui/material/TableHead";

function teamsSort(a, b) {
    if (a.wins !== b.wins) {
        return b.wins - a.wins;
    }
    else if (a.losses !== b.losses) {
        return a.losses - b.losses;
    }
    else if (a.ties !== b.ties) {
        return b.ties - a.ties;
    }
    else {
        return a.team_name.localeCompare(b.team_name);
    }
}

export default function TeamsTable(teamsData, configData) {
    return (
        <TableContainer component={Paper} className="FootballWinsTeamsTableContainer">
            <Table size="small" className="FootballWinsTeamsTable">
                <TableHead>
                    <TableRow>
                        <TableCell>Team</TableCell>
                        <TableCell>Owner</TableCell>
                        <TableCell>Wins</TableCell>
                        <TableCell>Losses</TableCell>
                        <TableCell>Ties</TableCell>
                        <TableCell>Exp Wins</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {teamsData
                        .sort(teamsSort)
                        .map((row) => (
                            <TableRow key={`FootballWinsTeamsRow${row.team_name}`} className={footballEntryNameClass(row.EntryName, configData.EntryName)}>
                                <TableCell>{row.team_name}</TableCell>
                                <TableCell><div dangerouslySetInnerHTML={{ __html: purify.sanitize(row.EntryName) }}/></TableCell>
                                <TableCell>{row.wins}</TableCell>
                                <TableCell>{row.losses}</TableCell>
                                <TableCell>{row.ties}</TableCell>
                                <TableCell>{row.exp_wins}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
