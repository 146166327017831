import React from 'react';
import FootballEventCell from "../components/FootballEventCell";
import {fixupTime, footballResultClass, getNewDate} from "../Utils";
import {Table, Paper, TableCell, TableRow, TableBody, TableContainer} from "@mui/material";

function cellClass(row, configData, teamResult) {
    let result;
    if (row.EntryName1 === configData.EntryName && row.EntryName2 === configData.EntryName) {
        return 'FootballTied';
    }
    if (row.EntryName1 === configData.EntryName) {
        result = teamResult(row.score1, row.score2, row.inProgress, row.started) || 3;
    }
    else if (row.EntryName2 === configData.EntryName) {
        result = teamResult(row.score2, row.score1, row.inProgress, row.started) || 3;
    }
    else {
        return '';
    }
    return footballResultClass(result);
}

function scoresSort(a,b) {
    const aFinal = (a.started === 1 && a.timeLeft === 0);
    const bFinal = (b.started === 1 && b.timeLeft === 0);

    if (aFinal !== bFinal)
        return aFinal ? 1 : -1;

    if (a.started !== b.started) {
        if (a.inProgress !== b.inProgress) {
            return a.inProgress ? -1 : 1;
        }
        else {
            return a.started ? -1 : 1;
        }
    }

    if (a.day !== b.day) {
        const aDate = getNewDate(a.day + "-00:00"), bDate = getNewDate(b.day + "-00:00");
        return aDate - bDate;
    }

    return a.team1.localeCompare(b.team1);
}

function dayFormat(day) {
    return (day.getMonth()+1) + '/' + day.getDate() + ' ' + fixupTime(day.getHours(), day.getMinutes());
}

export default function ScoresTable(scoresData, week, configData, teamResult) {
    return (
        <div className="FootballWinsStandingsGridItem">
            <h4>Week {week} Scores</h4>
            <TableContainer component={Paper} className="FootballStandingsGridItemTable">
                <Table size="small" className="FootballWinsScoresTable">
                    <TableBody>
                        {scoresData
                            .sort(scoresSort)
                            .map((row) => (
                                <TableRow key={`FootballScoresRow${row.ID}`}>
                                    <TableCell className={cellClass(row, configData, teamResult)}>
                                        <FootballEventCell
                                            team1={row.team1}
                                            team2={row.team2}
                                            score1={row.score1}
                                            score2={row.score2}
                                            started={!!row.started}
                                            inProgress={!!row.inProgress}
                                            status={!!row.started ? row.status : null}
                                            statusNote={row.statusNote}
                                            possession={row.possession}
                                            day={row.day}
                                            dayFormat={dayFormat}
                                            eventId={Number(row.ID)}
                                            team1Class=""
                                            team2Class=""
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
