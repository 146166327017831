import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {fetchP5} from "../Utils";
import SortableTable from "../components/SortableTable";

function FootballAllTime(props) {

    let navigate = useNavigate();
    const fetchUrl = `/api/v1/football/alltimeview`;
    const [isLoading, setIsLoading] = useState(true);
    const [allTimeData, setAllTimeData] = useState([]);

    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, fetchUrl, 'footballAllTime', props)
            .then(data => {
                setAllTimeData(data.data.all_time_data.data);
                if (isLoading) {
                    setIsLoading(false);
                }
            });
    };

    useEffect(() => {
        document.title = "Pick 5 All Time Football";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            fetchData();
        }
    }, []);

    function renderLoading() {
        return (
            <div>Loading...</div>
        )
    }
    const headerCells = [
        {
            label: 'Entry Name',
            id: 'football_entryname',
            numeric: false,
        },
        {
            label: 'Wins',
            id: 'wins',
            numeric: true,
        },
        {
            label: 'Losses',
            id: 'losses',
            numeric: true,
        },
        {
            label: 'Winnings',
            id: 'winnings',
            numeric: true,
        },
        {
            label: 'Years',
            id: 'years',
            numeric: true,
        },
    ];

    function rowKey(row) {
        return row.football_entryname;
    }
    
    function renderAllTimeData() {
        return (
            <SortableTable
                rows={allTimeData}
                headCells={headerCells}
                defaultOrderBy="wins"
                defaultOrder="desc"
                multiSelect={false}
                selected={[]}
                selectable={false}
                rowKey={rowKey}
            />
        )
    }
    
    return (
        <div className="FootballAllTimeRoot">
            <div className="FootballAllTimeContainer">
                <div>
                    <h4>Pick 5 Football All Time Standings</h4>
                </div>
                <div>
                    {isLoading ? renderLoading() : renderAllTimeData()}
                </div>
            </div>
        </div>
    );
}

export default FootballAllTime;
