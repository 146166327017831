import React, {useEffect, useState} from 'react';
import purify from "dompurify";
import {Table, Paper, TableCell, TableRow, TableBody, TableContainer, TableHead} from "@mui/material";
import {
    fetchP5,
    footballResultClass,
} from "../Utils";
import {Link, useNavigate} from "react-router-dom";

function FootballHistory(props) {
    let navigate = useNavigate();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const year = params.get('year');
    const entryName = params.get('entry');
    const title = `Pick 5 History for ${entryName}`;
    const fetchUrl = `/api/v1/football/history?year=${year}&entryname=${encodeURIComponent(entryName)}`;
    const [isLoading, setIsLoading] = useState(true);
    const [picks, setPicks] = useState([]);
    const [yearlyResults, setYearlyResults] = useState([]);
    const [yearTotals, setYearTotals] = useState({});
    
    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, fetchUrl, 'footballHistory', props)
            .then(data => {

                const picksData = [];
                const yearTotalsData = { wins: 0, losses: 0 };   
                data.data.picks.forEach((pick) => {
                    if (!picksData[pick.Week]) {
                        picksData[pick.Week] = {
                            wins: 0,
                            losses: 0,
                            picks: [],
                        };
                    }
                    if (pick.Result === 1) {
                        picksData[pick.Week].wins++;
                        yearTotalsData.wins++;
                    }
                    else {
                        picksData[pick.Week].losses++;
                        yearTotalsData.losses++;
                    }
                    
                    picksData[pick.Week].picks.push(pick);
                });

                setPicks(picksData);
                setYearTotals(yearTotalsData);
                setYearlyResults(data.data.yearly_results);

                if (isLoading) {
                    setIsLoading(false);
                }
            });
    };

    useEffect(() => {
        document.title = "Pick 5 Football History";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            fetchData();
        }
    }, []);

    function renderHistoryData() {
        return (
            <div>
                <div className="FootballHistoryDataContainer">
                    <div>
                        <h4>{year} Picks</h4>
                        <TableContainer component={Paper} className="FootballHistoryTableContainer">
                            <Table size="small" className="FootballHistoryTable">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Week</TableCell>
                                        <TableCell>Pick1</TableCell>
                                        <TableCell>Pick2</TableCell>
                                        <TableCell>Pick3</TableCell>
                                        <TableCell>Pick4</TableCell>
                                        <TableCell>Pick5</TableCell>
                                        <TableCell>Wins</TableCell>
                                        <TableCell>Losses</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {picks
                                        .map((row, week) => (
                                            <TableRow key={`FootballPicksRow${week}`}>
                                                <TableCell className="FootballHistoryResultCountCell">{week}</TableCell>
                                                {row.picks.map((pick) => {
                                                    return <TableCell className={footballResultClass(pick.Result)} key={`FootballPicksCell${pick.Pick}`}>{pick.Pick}</TableCell>
                                                })}
                                                {[...Array(5 - row.picks.length).keys()].map((pickNum) => {
                                                    return <TableCell key={`FootballPicksCellEmpty${pickNum}`}/>
                                                })}
                                                <TableCell className="FootballHistoryResultCountCell">{row.wins}</TableCell>
                                                <TableCell className="FootballHistoryResultCountCell">{row.losses}</TableCell>
                                            </TableRow>
                                        ))}
                                    <TableRow key={`FootballPicksRowTotal`}>
                                        <TableCell colSpan="6">Total</TableCell>
                                        <TableCell className="FootballHistoryResultCountCell">{yearTotals.wins}</TableCell>
                                        <TableCell className="FootballHistoryResultCountCell">{yearTotals.losses}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div>
                        <h4>Yearly Results</h4>
                        <TableContainer component={Paper} className="FootballHistoryTableContainer">
                            <Table size="small" className="FootballHistoryTable">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Year</TableCell>
                                        <TableCell>Wins</TableCell>
                                        <TableCell>Losses</TableCell>
                                        <TableCell>Points</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {yearlyResults.map((year) =>
                                        <TableRow key={`Result${year.year}`}>
                                            <TableCell>{year.year}</TableCell>
                                            <TableCell>{year.wins}</TableCell>
                                            <TableCell>{year.losses}</TableCell>
                                            <TableCell>{year.points}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            <div>
                <Link className="ContestLink" to="../football" key='link-football'>Return to the main page</Link>
            </div>
        </div>
        );
    }

    function renderLoading() {
        return <div>Loading</div>;
    }

    return (
            <div>
                <h3>
                    <div dangerouslySetInnerHTML={{ __html: purify.sanitize(title) }} />
                </h3>
                {isLoading ? renderLoading() : renderHistoryData()}
            </div>
        );
}

export default FootballHistory;