import React, {useEffect} from "react";
import {Link} from "react-router-dom";

function FootballWinsRules() {

    useEffect(() => {
        document.title = "Pick 5 Football Wins Rules";
    }, []);

    return (
        <div>
            <h4>Pick 5 NFL Wins Contest Rules</h4>
            <div>
                <p>Rules:
                    <ol>
                        <li>For each game a team wins their owner accrues 1 point.</li>
                        <li>Tie games result in a half point for each team owner.</li>
                        <li>The contest will go through the regular NFL season (18 weeks). It will not include the playoffs.</li>
                        <li>Payouts: 8 entries * 50 = 400 in the pot. Overall 1st, 2nd, & 3rd place winners will get: 250, 100, 50.</li>
                        <li>If multiple entries tie for an overall prize they will split the prize.</li>
                    </ol>

                </p>
            </div>
            <Link className="ContestLink" to="/footballwins" key='link-mainpage'>Return to the main page</Link>
        </div>
    );
}

export default FootballWinsRules;
