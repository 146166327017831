import React, {useEffect} from "react";
import {Link} from "react-router-dom";

function FootballRules() {

    useEffect(() => {
        document.title = "Pick 5 Football Rules";
    }, []);

    return (
        <div>
            <h4>Pick 5 Football Contest Rules</h4>
            <div>
                <p>Rules:
                    <ol>
                        <li>An entry must make 5 correct picks in a single week to win a share of the weekly prize.</li>
                        <li>Each winning entry in a week will win an equal share of the week's pot.</li>
                        <li>If no entry wins in a week, that week's pot is pushed to the next week.</li>
                        <li>If the final week of the contest is a push, more weeks will be added to the contest until there is a winner (the pot will not increase during extra weeks).</li>
                        <li>All picks must be submitted before the advertised game time. At game time the pick will be locked and made public on the website.</li>
                        <li>All picks are against the spread.</li>
                        <li>Spreads may be locked at any time by selecting the Locked option next to the pick. In that case the spread and pick are locked and cannot be changed. If the Locked option is not selected, the spread of the pick is updated every hour and locked at the advertised game time. For example: if you submit a pick Tuesday for a game on Wednesday, the spread will be updated every hour but will lock at the advertised game time and not be updated after that. If you select the Locked option with any pick, the spread and pick cannot be changed.</li>
                        <li>The site administrator reserves the right to correct any spread that is obviously wrong and made available by mistake. This site is highly automated and spreads are not vetted before becoming available. If an obviously bad spread becomes available, it will be corrected and any locked picks of that game will be unlocked and the current spread will be applied.</li>
                        <li>You cannot pick the same team in the same game multiple times.</li>
                        <li>The overall prize goes to the entry with the most wins.</li>
                        <li>If multiple entries tie for the overall prize they will split the prize.</li>
                        <li>The overall prizes will be awarded at the end of the scheduled season. Any extra weeks added to the end of the season (due to a final week push) won't affect the overall standings and payouts.</li>
                        <li>If a pick results in a push, then that pick results in a loss in the contest.</li>
                        <li>Close but no cigar prize: 100 goes to the entry with the most 4-1 weeks that didn't win any weekly or overall prize. (tiebreaker is total wins)</li>
                        <li>Payouts: 89 entries * 100 = 8,900 in the pot. 15 weeks * 475 each week = 7,125; 400 + 300 + 200 + 175 + 150 + 125 + 125 + 100 + 100 overall prizes + 100 no cigar prize + 7,125 weekly = 8,900 total.</li>
                    </ol>

                </p>
            </div>
            <Link className="ContestLink" to="/football" key='link-mainpage'>Return to the main page</Link>
        </div>
    );
}

export default FootballRules;
