import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {postP5} from "../Utils";
import Button from "@mui/material/Button";
import {TextField} from "@mui/material";

function SetPassword(props) {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [guid, setGuid] = useState('');
    const [mode, setMode] = useState('join');  // "join" or "reset"
    const [submitResult, setSubmitResult] = useState('');
    let navigate = useNavigate();
    
    useEffect(() => {
        document.title = "Pick 5 Set Password";
        const queryParameters = new URLSearchParams(window.location.search);
        setGuid(queryParameters.get("guid"));
        setUserName(queryParameters.get("user"));
        setMode(queryParameters.get("mode"));
    }, []);

    function handlePasswordChange(e) {
        setPassword(e.target.value);
    }
    
    function handleSubmit() {
        
        postP5(userName, guid.substring(0,10), mode === 'join' ? '/api/v1/na/joinuser' : '/api/v1/utils/setnewpassword',{
            guid,
            password,
        })            
            .then(response => response.json())
            .then(data => {
                    setSubmitResult(data.error ? data.error.message : (mode === 'join' ? 
                        "Success!  You are now ready to begin using the Pick 5 website.  You will be redirected there shortly." :
                        "Success!  Your password has been set. You may now sign into the the Pick 5 website.  You will be redirected there shortly."
                    ));
                    setTimeout(() => {
                        navigate('/');
                    }, 5000);
                }
            );
    }
    
    return (
        <div>
            <h3>Pick 5 {mode === 'join' ? 'New User' : 'Reset Password'}</h3>
            <div className="SetPasswordWelcomeMsg">
                {mode === 'join' ? 
                    `Welcome to the Pick 5 Website! Your username is ${userName}. You must now choose a password to use with the website. Do not lose your username or password as you'll need them to enter your picks each week (an email with your username and password will be sent to you once you have completed this step). Please enter a password (1-20 characters):` :
                    'Your password for the Pick 5 website has been reset.  Please enter a new password (1-20 characters):'
                }
            </div>
            <div>
                <div className="SetPasswordUserContainer">
                    <TextField fullWidth size="small" disabled value={userName} label="Username" helperText=" "/>
                </div>
                <div className="SetPasswordPasswordContainer">
                    <TextField fullWidth size="small" onChange={handlePasswordChange} label="Password" helperText="Please choose a password"/>
                </div>
            </div>
            <div>
                <Button disabled={password===''} onClick={handleSubmit}>Submit</Button>
                <div className="SetPasswordSubmitResult">{submitResult}</div>
            </div>
        </div>
    );
}

export default SetPassword;