import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {fetchP5, getDateString, getNewDate} from "../Utils";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {TableCell} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";

function SSF(props) {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [footballEvents, setFootballEvents] = useState([]);
    
    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, "/api/v1/ssf/pagedata", "ssf", props)
            .then(data => {
                setFootballEvents(props.getState().ssf.data.events.data);
            });
    }
            
    useEffect(() => {
        document.title = "Sports Stats Football";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            setIsLoading(false);
            fetchData();
        }
    }, []);

    function renderLoading() {
        return <div>Loading...</div>;
    }
    
    function renderData() {
        return (
            <div>
                <TableContainer>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Away Team</TableCell>
                                <TableCell>Away Spread</TableCell>
                                <TableCell>Score</TableCell>
                                <TableCell>Home Team</TableCell>
                                <TableCell>Home Spread</TableCell>
                                <TableCell>Score</TableCell>
                                <TableCell>Over/Under</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {footballEvents.map((event) =>
                            <TableRow>
                                <TableCell>{getDateString(getNewDate(event.date))}</TableCell>
                                <TableCell>{event.nickname1}</TableCell>
                                <TableCell>{event.spread1}</TableCell>
                                <TableCell>{event.score1}</TableCell>
                                <TableCell>{event.nickname2}</TableCell>
                                <TableCell>{event.spread2}</TableCell>
                                <TableCell>{event.score2}</TableCell>
                                <TableCell>{event.over_under}</TableCell>
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }
    
    function handleUpdateButtonClick() {
        fetchP5(props.getState().userName, props.getState().password, '/api/v1/ssf/update')
            .then(data => {
                fetchData();
            });
    }
    
    return (
        <div>
            <div className="SSFHeaderBar">
                <h2>Sports Stats Football</h2>
                <Button size="small" onClick={handleUpdateButtonClick}>Update</Button>
            </div>
            {isLoading ? renderLoading() : renderData()}
        </div>
    );
}

export default SSF;